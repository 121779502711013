import { Timer } from 'easytimer.js';
export default ($wire, { startSeconds }) => ({
    countdown: '00:00:00',
    displayDays: '00',
    displayHours: '00',
    displayMinutes: '00',
    displaySeconds: '00',
    startSeconds: startSeconds,
    displayDaysTrans: $wire.get('displayDaysTrans'),
    displayHoursTrans: $wire.get('displayHoursTrans'),

    init() {
        const timer = new Timer({
            countdown: true,
            startValues: {
                seconds: this.startSeconds,
            },
        });

        timer.start();

        this.countdown = timer.getTimeValues().toString();
        this.displayDays = timer.getTimeValues().days;
        this.displayHours = timer.getTimeValues().hours;
        this.displayMinutes = timer.getTimeValues().minutes;
        this.displaySeconds = timer.getTimeValues().seconds;

        this.displayDaysTrans = timer.getTimeValues().days;
        this.displayHoursTrans = timer.getTimeValues().hours;

        timer.addEventListener('secondsUpdated', e => {
            this.countdown = timer.getTimeValues().toString();
            this.displayDays = timer.getTimeValues().days;
            this.displayHours = timer.getTimeValues().hours;
            this.displayMinutes = timer.getTimeValues().minutes;
            this.displaySeconds = timer.getTimeValues().seconds;
        });

        timer.addEventListener('targetAchieved', e => {
            // this.value = "KABOOM!!";
        });
    },
    ...startSeconds,
});
