import Swiper, { FreeMode } from 'swiper';

export default selector => ({
    init() {
        new Swiper(selector, {
            modules: [FreeMode],
            slidesPerView: 'auto',
            freeMode: {
                enabled: true,
                momentum: false,
                momentumBounce: false,
                momentumVelocityRatio: 0,
                minimumVelocity: 0,
            },
            nested: true,
            loop: false,
            resistance: true,
            resistanceRatio: 0,
            grabCursor: true,
            allowTouchMove: true,
            speed: 500,
        });
    },
});
