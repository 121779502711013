import Swiper, { Navigation } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default $wire => ({
    mySwiper: null,

    init() {
        const slider = document.getElementById('featured-articles-slider');
        if (slider) {
            this.mySwiper = new Swiper(slider, {
                ...this.getSliderOptions(),
                on: {
                    slideChange: this.updateNavigationClasses.bind(this),
                    transitionEnd: this.updateNavigationClasses.bind(this),
                },
            });
            this.updateNavigationClasses();
        }
    },

    getSliderOptions() {
        return {
            loop: false,
            lazy: true,
            slidesPerView: 1.3,
            spaceBetween: 12,
            freeMode: true,
            modules: [Navigation],
            navigation: {
                nextEl: '#next-article',
                prevEl: '#prev-article',
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                },
            },
        };
    },

    updateNavigationClasses() {
        const nextEl = document.querySelector('#next-article');
        const prevEl = document.querySelector('#prev-article');

        if (this.mySwiper.isBeginning) {
            prevEl.classList.add('!bg-gray-500');
        } else {
            prevEl.classList.remove('!bg-gray-500');
        }

        if (this.mySwiper.isEnd) {
            nextEl.classList.add('!bg-gray-500');
        } else {
            nextEl.classList.remove('!bg-gray-500');
        }
    },
});
