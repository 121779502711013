export default ({ domain, name, value, ttl, secure, sameSite }) => ({
    init: function () {
        if (this.cookieExists()) {
            this.hideCookieDialog();
        } else {
            document.body.classList.add('overflow-hidden');
        }
    },

    consentWithCookies: function () {
        this.setCookie();
        this.hideCookieDialog();
    },

    cookieExists: function () {
        return document.cookie.split('; ').indexOf(`${name}=${value}`) !== -1;
    },

    hideCookieDialog: function () {
        const dialogs = document.getElementsByClassName('js-cookie-consent');
        for (let i = 0; i < dialogs.length; ++i) {
            dialogs[i].style.display = 'none';
        }
        document.body.classList.remove('overflow-hidden');
    },

    setCookie: function () {
        const date = new Date();
        date.setTime(date.getTime() + ttl * 24 * 60 * 60 * 1000);

        document.cookie = [
            `${name}=${value}`,
            `expires=${date.toUTCString()}`,
            `domain=${domain}`,
            'path=/',
            secure ? 'secure' : null,
            sameSite ? 'samesite' : null,
        ]
            .filter(item => item)
            .join(';');
    },
});
