export default ({ $wire }) => ({
    isOpen: $wire.entangle('showPasswordModal').live,
    modalTitle: $wire.entangle('modalTitle').live,
    open() {
        this.isOpen = true;
        document.body.style.overflow = 'hidden';
    },
    close() {
        this.isOpen = false;
        document.body.style.overflow = 'auto';
    },
});
