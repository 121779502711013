export default ($wire, $dispatch, url, index) => ({
    show: true,

    removeItem() {
        this.show = false;

        $wire.removeItem(index);
        $dispatch('removed-recent-search-item');
    },

    open() {
        window.open(url, '__blank');
    },
});
