import Swiper, { Navigation, Scrollbar, Pagination, Grid } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/grid';

export default () => ({
    init() {
        let reasonsSlider = document.getElementById('reasons_slider');
        let articlesSlider = document.getElementById('articles_slider');
        let testimonialsSlider = document.getElementById('testimonials_slider');
        let teamSlider = document.getElementById('team_slider');
        let prizesSlider = document.getElementById('prizes_slider');

        if (reasonsSlider != null) {
            this.reasonsSwiper = new Swiper(reasonsSlider, this.getReasonsSliderOptions());
        }
        if (articlesSlider != null) {
            this.articlesSwiper = new Swiper(articlesSlider, this.getArticlesSliderOptions());
        }
        if (testimonialsSlider != null) {
            this.testimonialsSwiper = new Swiper(testimonialsSlider, this.getTestimonialsSliderOptions());
        }
        if (teamSlider != null) {
            this.teamSwiper = new Swiper(teamSlider, this.getTeamSliderOptions());
        }
        if (prizesSlider != null) {
            this.prizesSwiper = new Swiper(prizesSlider, this.getPrizesSliderOptions());
        }
    },

    getReasonsSliderOptions() {
        return {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: false,
            grid: {
                rows: 1,
                fill: 'row',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.3,
                    grid: {
                        rows: 1,
                        fill: 'row',
                    },
                },
                992: {
                    slidesPerView: 3,
                    grid: {
                        rows: 2,
                        fill: 'row',
                    },
                    allowTouchMove: false,
                },
            },
            modules: [Pagination, Grid],
            pagination: {
                el: '.swiper-pagination',
            },
        };
    },

    getTeamSliderOptions() {
        return {
            slidesPerView: 1.2,
            spaceBetween: 20,
            loop: false,
            grid: {
                rows: 1,
                fill: 'row',
            },
            breakpoints: {
                768: {
                    slidesPerView: 4,
                    grid: {
                        rows: 2,
                        fill: 'row',
                    },
                    allowTouchMove: false,
                },
            },
            modules: [Grid, Navigation, Scrollbar],
            scrollbar: {
                el: '.team-scrollbar',
                hide: false,
                draggable: true,
            },
        };
    },

    getArticlesSliderOptions() {
        return {
            spaceBetween: 20,
            lazy: false,
            slidesPerView: 1.2,
            modules: [Navigation, Scrollbar],
            navigation: {
                nextEl: '.news-next',
                prevEl: '.news-prev',
                disabledClass: 'saturate-0 opacity-30',
            },
            scrollbar: {
                el: '.news-scrollbar',
                hide: false,
                draggable: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.2,
                },
                768: {
                    slidesPerView: 2.3,
                },
                992: {
                    slidesPerView: 3,
                },
            },
        };
    },

    getTestimonialsSliderOptions() {
        return {
            spaceBetween: 20,
            lazy: false,
            slidesPerView: 1.2,
            modules: [Navigation, Scrollbar],
            navigation: {
                nextEl: '.testimonials-next',
                prevEl: '.testimonials-prev',
                disabledClass: 'saturate-0 opacity-30',
            },
            scrollbar: {
                el: '.testimonials-scrollbar',
                hide: true,
                draggable: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.2,
                },
                768: {
                    slidesPerView: 2.3,
                },
            },
        };
    },

    getPrizesSliderOptions() {
        return {
            spaceBetween: 20,
            lazy: false,
            slidesPerView: 1.2,
            modules: [Navigation, Scrollbar],
            navigation: {
                nextEl: '.prizes-next',
                prevEl: '.prizes-prev',
                disabledClass: 'saturate-0 opacity-30',
            },
            scrollbar: {
                el: '.prizes-scrollbar',
                hide: false,
                draggable: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.2,
                },
                768: {
                    slidesPerView: 2.3,
                },
                992: {
                    slidesPerView: 4.2,
                },
            },
        };
    },
});
