export default ($wire, { defaultOpenItem = null }) => ({
    name: $wire.entangle('name').live,
    showToggleIcon: $wire.entangle('showToggleIcon').live,
    allowMultipleItemsOpen: $wire.entangle('allowMultipleItemsOpen').live,
    init() {
        let initiallyOpenItems = [];

        if (defaultOpenItem !== null) {
            initiallyOpenItems.push(defaultOpenItem);
        }

        Alpine.store(this.name, {
            openItems: initiallyOpenItems,
            allowMultipleItemsOpen: this.allowMultipleItemsOpen,
        });
    },
    get openItems() {
        let state = Alpine.store(this.name);

        return state['openItems'];
    },
});
