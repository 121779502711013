export default url => ({
    show: true,

    removeItem() {
        this.show = false;
    },

    open() {
        window.open(url, '__blank');
    },
});
