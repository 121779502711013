export default $wire => ({
    offerType: $wire.entangle('offerType'),
    categoryFilter: $wire.entangle('categoryFilter'),
    projectName: $wire.entangle('projectName'),
    defaultCategory: $wire.entangle('defaultCategory').live,
    sellOfferType: $wire.entangle('sellOfferType').live,
    projectOfferType: $wire.entangle('projectOfferType').live,
    loading: false,
    active: 'search_listings',
    showNumberOfProperties: false,
    init() {
        this.categoryFilter = this.defaultCategory;
        this.enableNumberOfProperties();
    },
    submit(offerType = null) {
        this.loading = true;

        if (offerType === this.projectOfferType) {
            $wire.call('searchProjects', true);
            return;
        }

        $wire.call('searchListings', offerType, true);
    },
    isActive(tab) {
        return tab == this.active;
    },
    setActive(value) {
        this.active = value;
        this.showNumberOfProperties = false;
        this.enableNumberOfProperties();
    },
    enableNumberOfProperties() {
        if (this.active == 'search_listings' || this.active == 'search_international') {
            this.showNumberOfProperties = true;
        }
    },
});
