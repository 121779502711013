export default ({ international, locationSearchRoute, selected, options, isProject }) => ({
    clickToShowResults: false,
    loadingLocations: false,
    hasLocationSearchResults: false,
    defaultSelected: false,
    international: international ?? false,
    locationSearchRoute: locationSearchRoute,
    locationSearchResults: [],
    search: '',
    selected: selected ?? null,
    selectedId: 0,
    selectedName: '',
    selectedDepth: 0,
    showResults: false,
    options: options ?? [],
    isProject: isProject ?? false,

    init() {
        if (this.selected) {
            this.search = this.selected['label'];
            this.defaultSelected = true;
        }
    },
    resetFilters() {
        this.search = '';
        this.locationSearchResults = [];
        this.hasLocationSearchResults = false;
        this.defaultSelected = false;
        this.showResults = false;
    },
    async searchLocation() {
        if (!this.search || this.search.length < 3) {
            this.locationSearchResults = [];
            this.hasLocationSearchResults = false;

            return;
        }

        this.loadingLocations = true;
        let data = null;

        if (!this.options.length) {
            let response = await axios.get(this.locationSearchRoute, {
                params: {
                    search: this.search,
                    international: this.international ? 1 : 0,
                },
            });

            data = response.data;
            this.hasLocationSearchResults = Object.keys(data).length > 0;
        } else {
            const searchQuery = this.removeDiacritics(this.search.toLowerCase());

            data = this.options
                .filter(location =>
                    this.removeDiacritics((location.custom_display || '').toLowerCase()).includes(searchQuery),
                )
                .map(location => ({ ...location }));

            this.hasLocationSearchResults = data.length > 0;
        }

        this.defaultSelected = false;
        this.clickToShowResults = true;
        this.loadingLocations = false;
        this.locationSearchResults = data;
        this.showResults = true;
    },
    selectedLocation(locationId, depth, displayText) {
        let eventName = this.isProject ? 'selected-project-location' : 'selected-location';
        let event = new CustomEvent(eventName, {
            detail: {
                id: locationId,
                depth: depth,
                displayText: displayText,
            },
        });
        window.dispatchEvent(event);

        this.search = displayText;
        this.locationSearchResults = [];
        this.hasLocationSearchResults = false;
        this.defaultSelected = false;
        this.showResults = false;
    },
    removeDiacritics(str) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
});
