import useMoney from '@resources/js/app/composables/useMoney.js';
import useObjects from '@resources/js/app/composables/general/useObjects.js';

const { formatMoney } = useMoney();
const { isObject, isNotEmty } = useObjects();

export default (
    $dispatch,
    {
        min,
        max,
        name,
        shape,
        defaultValue = 0,
        isCurrency = false,
        formatOptions = [],
        singularDecorator,
        pluralDecorator,
    },
) => ({
    value: 0,
    init() {
        this.setValue(defaultValue);

        document.addEventListener('range-input:set-value', event => {
            let options = event.detail;

            if (options.for === name && options.value <= max && options.value >= min && options.value !== this.value) {
                this.setValue(options.value);
            }
        });
    },

    get span() {
        return max - min;
    },

    get traversed() {
        return this.value - min;
    },

    get percentageProgress() {
        return Math.ceil((this.traversed / this.span) * 100);
    },

    get valueDecorator() {
        return parseInt(this.value) > 1 ? pluralDecorator : singularDecorator;
    },

    get formattedValue() {
        if (isCurrency === true && isObject(formatOptions) && isNotEmty(formatOptions)) {
            return formatMoney(
                this.value,
                formatOptions.currencySeparator,
                formatOptions.showCurrency ? formatOptions.primaryCurrency : null,
                formatOptions.currencyPosition ? formatOptions.currencyPosition : 'after',
            );
        }

        if (this.valueDecorator !== 'undefined') {
            return `${this.value} ${this.valueDecorator}`;
        }

        return this.value;
    },

    setValue(newValue) {
        this.value = newValue;

        this.update();
    },

    update() {
        if (shape === 'outline-pill') {
            let rangeSlider = document.querySelector('#range-' + name);

            rangeSlider.style.setProperty('--thumb-left', this.percentageProgress + '%');
        }

        $dispatch('value-changed', {
            value: this.value,
            for: name,
        });
    },
});
