import Splide from '@splidejs/splide';

export default ({ name, container, options, paginationStyle, paginationClasses, paginationPageClasses }) => ({
    splide: null,
    slideIndex: 1,
    lastSlideIndex: null,
    lastPage: null,
    currentPage: null,
    init() {
        const defaults = {
            breakpoints: {
                640: {
                    perPage: 1,
                },
                767: {
                    perPage: 2,
                },
                1024: {
                    perPage: 3,
                },
            },
        };
        const allOptions = {
            ...defaults,
            ...options,
            paginationStyle,
            paginationClasses,
            paginationPageClasses,
        };

        const setCurrentPage = page => {
            this.currentPage = page;
            Alpine.store(name, {
                currentPage: page,
                lastPage: this.lastPage,
            });
        };

        const setLastPage = page => {
            this.lastPage = page;
        };

        const setSlideIndex = index => {
            this.slideIndex = index;
        };

        const setLastSlideIndex = end => {
            this.lastSlideIndex = end;
        };

        const splideUpdated = () => {
            setLastSlideIndex(this.splide.Components.Controller.getEnd());
        };

        if (container) {
            this.splide = new Splide(container, allOptions);

            this.splide.on('arrows:updated', function (prev, next, prevIndex, nextIndex) {
                prevIndex === -1 ? (prev.style.display = 'none') : (prev.style.display = 'flex');
                nextIndex === -1 ? (next.style.display = 'none') : (next.style.display = 'flex');
            });

            this.splide.on('pagination:mounted', function (data, item) {
                setCurrentPage(item.page + 1);
                setLastPage(data.items.length);
            });

            this.splide.on('pagination:updated', function (data, prev, curr) {
                if (typeof curr !== 'undefined') {
                    setCurrentPage(curr.page + 1);
                }
            });

            this.splide.on('active', function (slide) {
                setSlideIndex(slide.index);
            });

            if (allOptions.paginationStyle === 'show-current-of-total') {
                this.splide.on('pagination:updated', function (data) {
                    paginationClasses = allOptions.paginationClasses.split(' ');
                    for (let i = 0; i < paginationClasses.length; i++) {
                        data.list.classList.add(paginationClasses[i]);
                    }

                    data.items.forEach(function (item, index) {
                        let page = String(item.page + 1);
                        if (options['customPagination'] === true) {
                            if (item['button'].classList.contains('is-active')) {
                                item.button.classList.add('!bg-transparent');
                                item.button.style.transform = 'scale(1)';
                                item.button.style.display = 'block';
                                item.button.innerText = page + ' / ' + data.items.length;
                            } else {
                                item.button.style.display = 'none';
                            }
                        } else if (item['button'].classList.contains('is-active') || index === data.items.length - 1) {
                            item.button.style.display = 'block';
                            if (item['button'].classList.contains('is-active') && index === data.items.length - 1) {
                                item.button.innerText = page + ' / ' + page;
                            } else if (item['button'].classList.contains('is-active')) {
                                item.button.innerText = page + ' / ';
                            } else if (index === data.items.length - 1) {
                                item.button.innerText = String(page);
                            }
                        } else {
                            item.button.style.display = 'none';
                        }
                    });
                });
            }

            if (allOptions.paginationStyle === 'show-all') {
                if (allOptions.paginationClasses) {
                    this.splide.on('pagination:updated', function (data) {
                        paginationClasses = allOptions.paginationClasses.split(' ');
                        for (let i = 0; i < paginationClasses.length; i++) {
                            data.list?.classList?.add(paginationClasses[i]);
                        }
                    });
                }

                if (allOptions.paginationPageClasses) {
                    this.splide.on('pagination:updated', function (data) {
                        paginationPageClasses = allOptions.paginationPageClasses.split(' ');
                        for (let i = 0; i < paginationPageClasses.length; i++) {
                            data.items.forEach(function (item, index) {
                                if (item['button'].classList.contains('is-active')) {
                                    item.button.classList.add('custom_carousel_nav_active');
                                } else {
                                    item.button.classList.remove('custom_carousel_nav_active');
                                    item.button.classList.add(paginationPageClasses[i]);
                                }
                            });
                        }
                    });
                }
            }

            this.splide.on('mounted', function () {
                splideUpdated();
            });

            this.splide.mount();
        }

        Alpine.store(name, {
            currentPage: this.currentPage,
            lastPage: this.lastPage,
        });
    },
    prevSlide() {
        this.splide.go('<');
    },
    nextSlide() {
        this.splide.go('>');
    },
    get showingFirstSlide() {
        return this.slideIndex === 0;
    },
    get showingLastSlide() {
        return this.slideIndex === this.lastSlideIndex;
    },
});
