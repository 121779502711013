export default () => ({
    loading: false,
    disabled: false,

    init() {
        this.loading = false;
        this.disabled = this.isDisabled();
    },

    startLoading() {
        // after the upgrade of Alpine, it started executing callbacks in a different order
        //
        // before:
        // 1. submit the form, handle @submit.prevent
        // 2. click the button, disable the button
        //
        // now:
        // 1. click the button, disable the button
        // 2. submit the form, handle @submit.prevent
        //
        // ...but, disabling the button prevents the submit from happening in the first place
        //
        // this delay ensures the form submission is attempted before the button is disabled, but
        // so quickly that the user won't be able to click on the button before it's disabled
        setTimeout(() => {
            this.loading = true;
        }, 1);
    },

    stopLoading() {
        this.loading = false;
    },

    disableButton() {
        this.disabled = true;
    },

    enableButton() {
        this.disabled = false;
    },

    isDisabled() {
        return !!this.$el.dataset.disabled;
    },

    stopProgress() {
        this.loading = false;
        this.disabled = false;
    },

    finishSubmission(event) {
        this.stopLoading();
        if (event.detail['data-bi'] === this.$el.dataset.bi) {
            this.disableButton();
        }

        if (event.detail['validationError'] === true) {
            this.enableButton();
        }
    },
});
