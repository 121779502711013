import Cookies from 'js-cookie';

export default ($wire, { eventName, payload, contactModal, visitorIdCookieName, initialPhoneNumberCtaText = '' }) => ({
    data: payload,
    medium: undefined,
    source: $wire.entangle('source').live,
    googleSource: $wire.entangle('googleSource').live,
    callTrackingEnabled: false,
    canShowPhoneModal: undefined,
    trackedPhoneNumber: $wire.entangle('trackedPhoneNumber').live,
    renderContextForPhoneNumberTracking: $wire.entangle('renderContextForPhoneNumberTracking').live,
    trackedPhoneNumberAcquisitionSuccess: $wire.entangle('trackedPhoneNumberAcquisitionSuccess').live,
    trackedPhoneNumberAcquisitionRequested: $wire.entangle('trackedPhoneNumberAcquisitionRequested').live,

    init: function () {
        const callTrackingSetting = payload['callTrackingEnabled'];

        if (typeof callTrackingSetting !== 'undefined' && callTrackingSetting === true) {
            this.callTrackingEnabled = true;
        }

        this.canShowPhoneModal = !this.callTrackingEnabled;

        Alpine.effect(() => {
            if (
                this.renderContextForPhoneNumberTracking === this.data['render_context'] &&
                this.trackedPhoneNumberAcquisitionRequested === true
            ) {
                if (this.trackedPhoneNumberAcquisitionSuccess === false) {
                    this.openModal();
                }
            }
        });
    },

    get displayablePhoneNumberOnCta() {
        return this.trackedPhoneNumber ?? initialPhoneNumberCtaText;
    },

    get showPhoneModal() {
        return (
            this.canShowPhoneModal === true ||
            (this.trackedPhoneNumberAcquisitionRequested === true &&
                this.trackedPhoneNumberAcquisitionSuccess === false)
        );
    },

    wantsToEnquireUsing: function (medium, source = null) {
        this.medium = medium;

        this.source = this.source ?? source;

        const visitorId = Cookies.get(visitorIdCookieName);

        if (typeof visitorId !== 'undefined') {
            this.data['visitor_id'] = visitorId;
        }

        this.data['source'] = this.source;

        if (this.callTrackingEnabled === true && this.medium === 'phone') {
            $wire.dispatchSelf('needsTrackedPhoneNumber', [this.data]);
        }

        if (this.medium === 'phone') {
            $wire.dispatch('trackShowPhoneNumberStart', { data: this.data });
        }

        if (this.medium === 'email') {
            $wire.dispatch('trackEmailEnquiryStart', { data: this.data });
        }

        if (this.medium !== 'phone' || this.showPhoneModal === true) {
            this.openModal();
        }
    },

    openModal: function () {
        this.data['type'] = this.medium;
        this.data['source'] = this.source;
        this.data['googleSource'] = this.googleSource;
        $wire.dispatchTo(contactModal, eventName, { data: this.data });
    },
});
