import { Timer } from 'easytimer.js';
export default ({ startSeconds, translations }) => ({
    countdown: '00:00:00',
    displayDays: '00',
    displayHours: '00',
    displayMinutes: '00',
    displaySeconds: '00',
    startSeconds: startSeconds,
    translations: translations,
    displayDaysTrans: '',
    displayHoursTrans: '',

    init() {
        const timer = new Timer({
            countdown: true,
            startValues: {
                seconds: this.startSeconds,
            },
        });

        timer.start();

        this.countdown = timer.getTimeValues().toString();
        this.displayDays = timer.getTimeValues().days;
        this.displayHours = timer.getTimeValues().hours;
        this.displayMinutes = timer.getTimeValues().minutes;
        this.displaySeconds = timer.getTimeValues().seconds;

        if (timer.getTimeValues().days === 1) {
            this.displayDaysTrans = this.translations['days-single'];
        }
        if (timer.getTimeValues().days > 1) {
            this.displayDaysTrans = this.translations['days-multiple'];
        }

        if (timer.getTimeValues().hours === 1) {
            this.displayHoursTrans = this.translations['hours-single'];
        }
        if (timer.getTimeValues().hours > 1) {
            this.displayHoursTrans = this.translations['hours-multiple'];
        }

        timer.addEventListener('secondsUpdated', e => {
            this.countdown = timer.getTimeValues().toString();
            this.displayDays = timer.getTimeValues().days;
            this.displayHours = timer.getTimeValues().hours;
            this.displayMinutes = timer.getTimeValues().minutes;
            this.displaySeconds = timer.getTimeValues().seconds;
        });
    },
    ...startSeconds,
});
