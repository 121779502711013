import cloneDeep from 'lodash.clonedeep';

export default () => ({
    updateOfferType(offerType) {
        this.offerType = offerType;
    },

    updateAreaFilter(payload) {
        if (typeof this[payload.name] !== 'undefined') {
            this[payload.name] = payload.value;
        }
    },

    updateDataProperties(payload) {
        let selectedDataProperties = cloneDeep(this.selectedDataPropertiesFilter);

        if (payload.name && typeof payload.selected !== 'undefined') {
            if (payload.selected === true) {
                selectedDataProperties[payload.name].push(payload.value);
            } else {
                let index = selectedDataProperties[payload.name].indexOf(payload.value);

                if (index > -1) {
                    selectedDataProperties[payload.name].splice(index, 1);
                }
            }
        }

        this.selectedDataPropertiesFilter = selectedDataProperties;
    },
});
