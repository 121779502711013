import tippy from 'tippy.js';

export default ({
    id,
    domId,
    element,
    content,
    theme = null,
    isHtml = false,
    allowHTML = false,
    placement = 'top',
    matchElementWidth = false,
    trigger = 'mouseenter focus',
}) => ({
    options: {},
    instance: null,
    init() {
        this.options = {
            placement,
            trigger,
        };

        if (allowHTML === true) {
            this.options.allowHTML = true;
        }

        if (isHtml === true) {
            this.options.allowHTML = true;
            content = document.getElementById(domId)?.innerHTML ?? '';
        }

        this.options.content = content;

        if (theme !== null) {
            this.options.theme = theme;
        }

        let maxWidth = 'none';

        if (matchElementWidth === true) {
            maxWidth = element.offsetWidth;
        }

        if (matchElementWidth === 'x2') {
            maxWidth = element.offsetWidth * 2;
        }

        if (matchElementWidth === 'x6') {
            maxWidth = element.offsetWidth * 6;
        }

        if (matchElementWidth === 'x15') {
            maxWidth = element.offsetWidth * 15;
        }

        if (matchElementWidth === 'x20') {
            maxWidth = element.offsetWidth * 20;
        }

        this.options.maxWidth = maxWidth;

        if (trigger !== 'manual') {
            this.instance = tippy(element, this.options);
        }
    },

    destroy() {
        if (this.instance) {
            this.instance.destroy();
        }
    },

    triggerManualToolTip(destroyAfterSeconds) {
        let tootTip = tippy(element, this.options);

        tootTip.show();

        if (destroyAfterSeconds) {
            setTimeout(() => {
                tootTip.destroy();
            }, destroyAfterSeconds * 1000);
        }
    },
});
