export default $wire => ({
    index: $wire.entangle('index').live,
    title: $wire.entangle('title').live,
    parentName: $wire.entangle('parentName').live,
    description: $wire.entangle('description').live,
    toggle() {
        this.isOpen ? this.collapse() : this.open();
    },
    open() {
        let currentlyOpenItems = this.openItems;

        if (this.allowMultipleItemsOpen !== true) {
            currentlyOpenItems = [];
        }

        currentlyOpenItems.push(this.index);

        this.setOpenItems(currentlyOpenItems);
    },
    collapse() {
        let currentlyOpenItems = this.openItems;
        let indexOfItem = currentlyOpenItems.indexOf(this.index);
        if (indexOfItem > -1) {
            currentlyOpenItems.splice(indexOfItem, 1);
            this.setOpenItems(currentlyOpenItems);
        }
    },
    setOpenItems(items) {
        Alpine.store(this.parentName, {
            openItems: items,
            allowMultipleItemsOpen: this.allowMultipleItemsOpen,
        });
    },
    get parentState() {
        return Alpine.store(this.parentName);
    },
    get openItems() {
        return this.parentState['openItems'];
    },
    get allowMultipleItemsOpen() {
        return this.parentState['allowMultipleItemsOpen'];
    },
    get isOpen() {
        return this.openItems.includes(this.index);
    },
});
