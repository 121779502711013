export default ($wire, $dispatch, name) => ({
    open: false,
    label: $wire.entangle('label').live,
    options: $wire.entangle('options').live,
    selected: $wire.entangle('selected'),
    emitEvent: $wire.entangle('emitEvent').live,
    emitEventTo: $wire.entangle('emitEventTo').live,
    closeOnSelect: $wire.entangle('closeOnSelect').live,
    emitEventToBrowser: $wire.entangle('emitEventToBrowser').live,

    init() {
        document.addEventListener('custom-select:set-value', event => {
            let options = event.detail;

            if (options.for === name) {
                this.select(options.value);
            }
        });
    },

    select(option) {
        this.selected = option;
        if (this.emitEventToBrowser) {
            $dispatch(this.emitEvent ?? 'select-changed', option);
        } else if (this.emitEvent) {
            if (this.emitEventTo) {
                $wire.dispatchTo(this.emitEventTo, this.emitEvent, [option]);
            } else {
                $wire.dispatch(this.emitEvent, [option]);
            }
        }

        if (this.closeOnSelect) {
            this.hideOptions();
        }
    },

    showOptions() {
        this.open = true;
    },

    hideOptions() {
        this.open = false;
    },

    get isSelected() {
        return this.selected != null && this.selected !== '';
    },

    get selectedLabel() {
        return this.options[this.selected];
    },
});
