import Swiper, { Navigation, Scrollbar, Controller } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default $wire => ({
    mySwiper: null,
    myModalSwiper: null,
    myThumbsSwiper: null,
    id: $wire.entangle('progressId').live,

    init() {
        let slider = document.getElementById('progress_slider_' + this.id);
        let modalSlider = document.getElementById('modal_progress_slider_' + this.id);
        let thumbsSlider = document.getElementById('modal_thumbs_progress_slider_' + this.id);

        if (thumbsSlider != null) {
            this.myThumbsSwiper = new Swiper(thumbsSlider, this.getThumbsSliderOptions());
        }

        if (modalSlider != null) {
            this.myModalSwiper = new Swiper(modalSlider, this.getModalSliderOptions());
            this.myModalSwiper.controller.control = this.myThumbsSwiper;
            this.myThumbsSwiper.controller.control = this.myModalSwiper;
        }

        if (slider != null) {
            this.mySwiper = new Swiper(slider, this.getSliderOptions());
            this.mySwiper.on('click touchstart', swiper => {
                const clickedIndex = swiper.clickedIndex;
                this.openProgressModal(clickedIndex);
            });
        }

        this.updateSlideCount(this.mySwiper.activeIndex + 1, this.mySwiper.slides.length);

        this.mySwiper.on('slideChange', () => {
            this.updateSlideCount(this.mySwiper.activeIndex + 1, this.mySwiper.slides.length);
        });

        this.myModalSwiper.on('slideChange', () => {
            this.updateModalSlideCount(this.myModalSwiper.activeIndex + 1, this.myModalSwiper.slides.length);
        });
    },

    getSliderOptions() {
        return {
            observer: true,
            observeParents: true,
            lazy: true,
            spaceBetween: 20,
            slidesPerView: 1.3,
            freeMode: true,
            watchSlidesProgress: true,
            modules: [Navigation],
            navigation: {
                nextEl: document.getElementById('next-' + this.id),
                prevEl: document.getElementById('prev-' + this.id),
            },
            breakpoints: {
                /* Above */
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
            },
        };
    },

    getThumbsSliderOptions() {
        return {
            observer: true,
            observeParents: true,
            spaceBetween: 10,
            lazy: false,
            slidesPerView: 'auto',
            freeMode: true,
            clickable: true,
            slideToClickedSlide: true,
            watchSlidesProgress: true,
            history: {
                replaceState: true,
            },
            modules: [Scrollbar, Controller],
            scrollbar: {
                el: '#scrollbar-thumbs-progress-' + this.id,
                draggable: true,
            },
        };
    },

    getModalSliderOptions() {
        return {
            observer: true,
            observeParents: true,
            lazy: true,
            slidesPerView: 1,
            freeMode: true,
            modules: [Navigation, Scrollbar, Controller],
            watchSlidesProgress: true,
            slideToClickedSlide: true,
            history: {
                replaceState: true,
            },
            navigation: {
                nextEl: document.getElementById('modal-next-' + this.id),
                prevEl: document.getElementById('modal-prev-' + this.id),
            },
            scrollbar: {
                el: '#scrollbar-modal-progress-' + this.id,
                draggable: true,
            },
        };
    },

    openProgressModal(clickedIndex) {
        setTimeout(() => {
            this.myModalSwiper.slideTo(clickedIndex);
            this.myThumbsSwiper.slideTo(clickedIndex);
            this.updateModalSlideCount(this.myModalSwiper.activeIndex + 1, this.myModalSwiper.slides.length);
        }, 200);
    },

    updateSlideCount(currentIndex, totalSlides) {
        const countElement = document.getElementById('slide-count-' + this.id);
        if (countElement) {
            countElement.textContent = currentIndex + ' / ' + totalSlides;
        }
    },

    updateModalSlideCount(currentIndex, totalSlides) {
        const countModalElement = document.getElementById('modal-slide-count-' + this.id);
        if (countModalElement) {
            countModalElement.textContent = currentIndex + ' / ' + totalSlides;
        }
    },
});
