import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@resources/css/leaflet.rrose.css';
import '@resources/css/leaflet-map.css';
import pin from '@resources/svg/marker-primary-fill.svg';

export default ({ mapUrl, mapTilesSubdomains, center, centerType, zoom, mapId, zoomControl }) => ({
    map: null,

    init() {
        const self = this;
        // Lazy initialise map
        window.addEventListener('load', () => {
            requestAnimationFrame(() => {
                self.createMap();
            });
        });
    },

    async createMap() {
        document.getElementById(mapId).innerHTML = '';
        this.map = L.map(mapId, {
            boxZoom: false,
            debounceMoveend: true,
            doubleClickZoom: false,
            fadeAnimation: false,
            markerZoomAnimation: false,
            maxZoom: 18,
            minZoom: 9,
            zoomAnimation: false,
            zoomControl: zoomControl,
        }).on('load', async () => {
            const self = this;
            setTimeout(function () {
                self.map.invalidateSize(true);
            }, 100);
        });

        this.map.setView(center, zoom);

        new L.tileLayer(mapUrl, {
            opacity: 0.7,
            zIndex: 30,
            subdomains: mapTilesSubdomains,
            detectRetina: false,
        }).addTo(this.map);

        const icon = L.icon({
            iconUrl: pin,
            iconSize: [28, 32],
            iconAnchor: [14, 32],
        });

        if (centerType === 'address') {
            return new L.marker(center, { icon }).addTo(this.map);
        }
    },
});
