import Swiper, { Navigation, Scrollbar, Controller } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default $wire => ({
    mySwiper: null,
    id: $wire.entangle('roomId').live,

    init() {
        let slider = document.getElementById('project_slider_' + this.id);

        if (slider != null) {
            const parentSlider = slider.closest('.developer-slider');
            const slidesPerView = parentSlider ? 5.2 : 3.4;
            this.mySwiper = new Swiper(slider, this.getSliderOptions(slidesPerView));
        }
    },

    getSliderOptions(slidesPerView) {
        return {
            observer: true,
            observeParents: true,
            lazy: true,
            spaceBetween: 8,
            slidesPerView: 1.5,
            spaceBetween: 16,
            freeMode: true,
            watchSlidesProgress: true,
            modules: [Navigation],
            navigation: {
                nextEl: document.getElementById('next-' + this.id),
                prevEl: document.getElementById('prev-' + this.id),
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: slidesPerView,
                },
            },
        };
    },

    getModalSliderOptions() {
        return {
            observer: true,
            observeParents: true,
            lazy: true,
            slidesPerView: 1,
            freeMode: true,
            modules: [Navigation, Scrollbar, Controller],
            watchSlidesProgress: true,
            slideToClickedSlide: true,
            history: {
                replaceState: true,
            },
            navigation: {
                nextEl: document.getElementById('modal-next-' + this.id),
                prevEl: document.getElementById('modal-prev-' + this.id),
            },
        };
    },
});
