import Inputmask from 'inputmask';

export default (symbol, value = null) => ({
    value: '',

    init() {
        if (value !== null) {
            this.value = value;
        }

        Inputmask('currency', {
            numericInput: true,
            rightAlign: false,
            autoUnmask: true,
            prefix: `${symbol} `,
            digits: 0,
            onBeforeMask: function (value) {
                if (null === value) {
                    value = '0';
                }

                return value;
            },
        }).mask(this.$refs.price);
    },
});
