export default $wire => ({
    showMobileAppBanner: $wire.get('showMobileAppBanner'),
    appUrlHost: $wire.get('appUrlHost') ?? '',
    appStoreUrl: $wire.get('appStoreUrl') ?? '',
    bannerIsVisible: false,

    async init() {
        if (!this.appUrlHost || !this.appStoreUrl || !this.checkIsAppleOrAndroidDevice()) {
            console.log('Mobile app banner is not supported on this device');
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const utmParams = ['utm_source', 'utm_medium', 'utm_campaign'];
        const utmString = utmParams
            .map(param => (urlParams.get(param) ? `${param}=${urlParams.get(param)}` : ''))
            .filter(Boolean)
            .join('&');

        if (utmString) {
            this.appUrlHost += `?${utmString}`;
            this.appStoreUrl.ios += `?${utmString}`;
            this.appStoreUrl.android += `?${utmString}`;
        }

        // Prod
        // if (this.showMobileAppBanner) {
        //     this.showBanner();
        // }

        // Test
        const urlParamsTest = new URLSearchParams(window.location.search);
        if (urlParamsTest.get('showMobileAppBanner') === 'true') {
            this.showBanner();
        }
    },

    showBanner() {
        this.bannerIsVisible = true;
        document.body.style.overflow = 'hidden';
    },

    hideBanner() {
        this.bannerIsVisible = false;
        document.body.style.overflow = 'auto';
        $wire.hideAppBannerModal();
    },

    openApp() {
        let self = this;
        const url = new URL(window.location.href);
        url.protocol = 'https:';
        url.hostname = this.appUrlHost;
        if (url.pathname === '/') {
            url.pathname = '/acasa';
        }

        url.pathname = '/h8iI?deep_link_value=' + btoa(url.pathname + url.search);
        url.search = '';

        setTimeout(function () {
            // 2. If app is not installed, redirect to fallback
            if (document.hasFocus()) {
                self.downloadApp();
            }
        }, 1000);

        // 1. Try to open the app using a deep link
        window.location = url.toString();
    },

    downloadApp() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let storeUrl = this.appStoreUrl.android; // Default to Android

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            storeUrl = this.appStoreUrl.ios;
        }

        window.location = storeUrl; // Redirect to app store
    },

    checkIsAppleOrAndroidDevice() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
});
