export default ({ $wire }) => ({
    isOpen: $wire.entangle('isOpen').live,

    init() {
        this.$watch('isOpen', value => {
            if (value !== true) {
                this.onClose();
            } else {
                this.onOpen();
            }
        });
    },

    onOpen() {
        document.body.style.overflow = 'hidden';
        document.body.setAttribute('data-modal-contact-shown', 'true');
    },

    onClose() {
        document.body.style.overflow = 'auto';
        document.body.setAttribute('data-modal-contact-shown', 'false');
    },
});
