export default ($wire, $dispatch, { authCheck }) => ({
    authCheck: undefined,
    listingId: $wire.listingId,
    isFavourite: $wire.entangle('isFavourite').live,

    init() {
        const self = this;

        if (!self.initialised) {
            document.addEventListener('updateIsFavourite' + this.listingId, event => {
                self.updateIsFavourite(event.detail);
            });

            if (document.readyState === 'complete' || document.readyState === 'interactive') {
                self.addPendingFavourite();
            } else {
                document.addEventListener('DOMContentLoaded', () => {
                    self.addPendingFavourite();
                });
            }
            self.initialised = true;
        }
    },

    toggleFavourite(popUpData = null, forceAdd = false) {
        if (authCheck) {
            this.isFavourite = !this.isFavourite || forceAdd;
            document.dispatchEvent(new CustomEvent('updateIsFavourite' + this.listingId, { detail: this.isFavourite }));
            this.isFavourite ? $wire.addFavourite() : $wire.removeFavourite();
        } else {
            if (popUpData) {
                $dispatch('open-pop-up-auth', popUpData);
            }
        }
    },

    updateIsFavourite(value) {
        self.isFavourite = value;
        setTimeout(function () {
            $wire.updateTotalFavourites();
        }, 500);
    },

    /*
     * Store the favourite action in local storage and redirect to the login page.
     */
    storeSaveFavouriteAction(loginLink) {
        localStorage.setItem('storePendingFavorite', JSON.stringify(this.listingId));
        $wire.setRedirectUrl(window.location.href);
        window.location.href = loginLink;
    },

    /*
     * Check if there is a pending save favourite action and if so, execute the action.
     */
    addPendingFavourite() {
        if (localStorage.getItem('storePendingFavorite')) {
            let storageListingId = JSON.parse(localStorage.getItem('storePendingFavorite'));
            if (parseInt(storageListingId) === parseInt(this.listingId)) {
                this.toggleFavourite(null, true);
                localStorage.removeItem('storePendingFavorite');
            }
        }
    },
});
