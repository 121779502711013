import { watch } from 'vue';

export default () => ({
    toggleMobileMenu: false,
    openPressed: false,

    init() {
        this.$watch('toggleMobileMenu', value => {
            if (value) {
                this.openPressed = true;
                document.dispatchEvent(new CustomEvent('update-user-menu'));
            }
        });
    },

    toggleMobileMenuAlternate() {
        this.toggleMobileMenu = !this.toggleMobileMenu;
        if (this.toggleMobileMenu) {
            this.toggleMobileMenuOn();
        } else {
            this.toggleMobileMenuOff();
        }
    },

    toggleMobileMenuOn(toggleMobileMenu = true) {
        this.toggleMobileMenu = toggleMobileMenu;
        document.body.style.overflow = 'hidden';
        document.querySelector('[ref="mainCanvas"]').classList.add('openMenu');
    },

    toggleMobileMenuOff(toggleMobileMenu = false) {
        this.toggleMobileMenu = toggleMobileMenu;
        document.body.style.overflow = 'auto';
        document.querySelector('[ref="mainCanvas"]').classList.remove('openMenu');
    },

    displayMobileMenu() {
        return this.toggleMobileMenu;
    },
});
