export default function useObjects() {
    function isObject(object) {
        return object !== null && typeof object === 'object' && !Array.isArray(object);
    }

    function isNotEmty(object) {
        return Object.keys(object).length > 0;
    }

    return {
        isObject,
        isNotEmty,
    };
}
