import axios from 'axios';

export default $wire => ({
    token: document.head.querySelector('meta[name="csrf-token"]'),
    slots: 0,
    maxTickets: 0,
    displayPayablePrice: 0,
    displayEarlyBirdPayablePrice: 0,
    paymentUrl: '',
    envKey: '',
    data: '',
    ticketFormsContainer: document.getElementById('ticket-forms-container'),
    expandedTickets: { 1: true },
    selectedPaymentType: 'company',
    loading: false,
    companyName: null,
    companyRegistrationNumber: null,
    companyLocality: null,
    companyAddress: null,
    companyBankAccount: null,
    privatePersonName: null,
    privatePersonAddress: null,
    privatePersonEmail: null,
    privatePersonCi: null,
    companyEmail: null,
    searchVat: null,
    processError: null,
    errors: {},

    init() {
        try {
            this.$watch('selectedPaymentType', value => {
                this.errors = {};
                this.privatePersonName = null;
                this.privatePersonEmail = null;
                this.privatePersonAddress = null;
                this.privatePersonCi = null;
                this.companyEmail = null;
                this.searchVat = null;
            });

            if (this.availableSlots && this.price && this.earlyBirdDiscount) {
                this.increaseSlot(this.price, this.earlyBirdDiscount);
            }

            document.getElementById('mainContent').className = '';
        } catch (error) {}
    },

    increaseSlot() {
        if (this.slots < this.availableSlots) {
            this.slots++;
            this.calcTotalPrice();
            this.renderTicketForms();
        }
    },
    decreaseSlot() {
        if (this.slots > 1) {
            this.slots--;
            this.calcTotalPrice();
            this.renderTicketForms();
        }
    },
    calcTotalPrice() {
        try {
            // Calculate raw total
            let slotPrice = 0;

            if (!this.isEarlyBirdActive && this.slots >= 3 && this.slots <= 9) {
                slotPrice = this.threePlusSlotVolumeDiscount;
            } else if (!this.isEarlyBirdActive && this.slots >= 10) {
                slotPrice = this.tenPlusSlotVolumeDiscount;
            } else {
                slotPrice = this.price;
            }

            const finalEarlyBirdPrice = this.earlyBirdDiscount * this.slots;
            const finalPrice = slotPrice * this.slots;

            this.displayPayablePrice = finalPrice + ' lei +TVA';
            this.displayEarlyBirdPayablePrice = finalEarlyBirdPrice + ' lei +TVA';
        } catch (err) {}
    },
    renderTicketForms() {
        try {
            const currentForms = this.ticketFormsContainer.children.length;

            if (this.slots > currentForms) {
                // Add new forms
                for (let i = currentForms + 1; i <= this.slots; i++) {
                    this.addTicketForm(i);
                }
            } else if (this.slots < currentForms) {
                // Remove excess forms
                for (let i = currentForms; i > this.slots; i--) {
                    const formToRemove = document.getElementById(`ticket-${i}`);
                    if (formToRemove) {
                        this.ticketFormsContainer.removeChild(formToRemove);
                    }
                    delete this.expandedTickets[i];
                }
            }
        } catch (error) {
            console.log('unable to add ticket');
        }
    },
    addTicketForm(i) {
        try {
            // Initialize expanded state for new tickets if not already set
            if (this.expandedTickets[i] === undefined) {
                this.expandedTickets[i] = false;
            }

            const ticketDetails = document.createElement('div');
            ticketDetails.className = 'flex flex-col space-y-3 rounded-lg bg-neutral-75 p-4 4k:space-y-6 4k:p-10';
            ticketDetails.id = `ticket-${i}`;

            // Create ticket header
            const ticketHeader = document.createElement('div');
            ticketHeader.className = 'flex flex-row justify-between';

            const ticketTitle = document.createElement('h3');
            ticketTitle.textContent = `Detalii Bilet ${i}`;
            ticketTitle.className = 'text-left text-base font-semibold leading-6 4k:text-3xl';

            const toggleBtn = document.createElement('button');
            toggleBtn.className = 'toggle-btn 4k:text-3xl';
            toggleBtn.id = `toggle-btn-${i}`;
            toggleBtn.innerHTML = this.expandedTickets[i] ? '▲' : '▼';

            ticketHeader.appendChild(ticketTitle);
            ticketHeader.appendChild(toggleBtn);

            // Create ticket form
            const ticketForm = document.createElement('div');
            ticketForm.className = 'flex flex-col space-y-3 4k:space-y-10';
            ticketForm.id = `ticket-form-${i}`;
            // Set initial display state using JavaScript
            ticketForm.style.display = this.expandedTickets[i] ? 'block' : 'none';

            // Name field
            const nameGroup = document.createElement('div');

            const nameInput = document.createElement('input');
            nameInput.type = 'text';
            nameInput.placeholder = 'Nume prenume';
            nameInput.id = `name-${i}`;
            nameInput.className = 'w-full rounded-lg border-none 4k:text-3xl 4k:p-4';

            nameGroup.appendChild(nameInput);

            // Email field
            const emailGroup = document.createElement('div');

            const emailInput = document.createElement('input');
            emailInput.type = 'email';
            emailInput.placeholder = 'Email';
            emailInput.id = `email-${i}`;
            emailInput.className = 'w-full rounded-lg border-none 4k:text-3xl 4k:p-4';

            emailGroup.appendChild(emailInput);

            // Phone field
            const phoneGroup = document.createElement('div');

            const phoneInput = document.createElement('input');
            phoneInput.type = 'tel';
            phoneInput.placeholder = 'Telefon';
            phoneInput.id = `phone-${i}`;
            phoneInput.className = 'w-full rounded-lg border-none 4k:text-3xl 4k:p-4';

            phoneGroup.appendChild(phoneInput);

            // Add fields to form
            ticketForm.appendChild(nameGroup);
            ticketForm.appendChild(emailGroup);
            ticketForm.appendChild(phoneGroup);

            // Add header and form to ticket details
            ticketDetails.appendChild(ticketHeader);
            ticketDetails.appendChild(ticketForm);

            // Add ticket details to container
            this.ticketFormsContainer.appendChild(ticketDetails);

            // Add toggle functionality using JavaScript
            // Use IIFE to capture the current value of i
            (function (ticketNumber, expandedTickets) {
                ticketHeader.addEventListener('click', function () {
                    // Update expanded state
                    expandedTickets[ticketNumber] = !expandedTickets[ticketNumber];

                    // Get the ticket form element
                    const ticketForm = document.getElementById(`ticket-form-${ticketNumber}`);
                    const toggleBtn = document.getElementById(`toggle-btn-${ticketNumber}`);

                    // Toggle display using JavaScript
                    if (expandedTickets[ticketNumber]) {
                        ticketForm.style.display = 'block';
                        toggleBtn.innerHTML = '▲';
                    } else {
                        ticketForm.style.display = 'none';
                        toggleBtn.innerHTML = '▼';
                    }
                });
            })(i, this.expandedTickets);
        } catch (error) {
            this.ticketFormsContainer.innerHTML = '';
        }
    },
    collectFormData() {
        try {
            const productId = document.getElementById('productSelect');

            // Get billing details
            let details = {};

            if (this.selectedPaymentType === 'private_person') {
                details = {
                    name: this.privatePersonName,
                    personal_email: this.privatePersonEmail,
                    address: this.privatePersonAddress,
                    person_ci: this.privatePersonCi,
                    type: this.selectedPaymentType,
                };
            } else {
                details = {
                    company_email: this.companyEmail,
                    name: this.companyName,
                    cui: this.searchVat,
                    type: this.selectedPaymentType,
                    company_registration_number: this.companyRegistrationNumber,
                    company_locality: this.companyLocality,
                    company_address: this.companyAddress,
                    company_bank_account: this.companyBankAccount,
                };
            }

            // Get ticket details
            const tickets = [];
            for (let i = 1; i <= this.slots; i++) {
                const nameInput = document.getElementById(`name-${i}`);
                const emailInput = document.getElementById(`email-${i}`);
                const phoneInput = document.getElementById(`phone-${i}`);

                tickets.push({
                    name: nameInput.value ?? null,
                    email: emailInput.value ?? null,
                    phone: phoneInput.value ?? null,
                });
            }

            // Format data as JSON
            return {
                details: details,
                tickets: tickets,
                product_id: productId.value,
            };
        } catch (error) {
            return {
                details: {
                    name: null,
                    address: null,
                    person_ci: null,
                },
                tickets: [],
                product_id: null,
            };
        }
    },
    processFormData() {
        try {
            this.validateForms();

            if (Object.keys(this.errors).length > 0) {
                return;
            }

            this.clearTicketFormErrors();

            this.loading = true;
            const payload = this.collectFormData();

            const formData = {
                details: payload.details,
                tickets: payload.tickets,
                product_id: payload.product_id,
            };

            axios
                .post(window.route('hub-product.card-payment'), formData, {
                    headers: {
                        Accept: 'application/json',
                        'X-CSRF-TOKEN': this.token.content,
                    },
                })
                .then(response => {
                    const responseObject = response.data;

                    if (responseObject.error) {
                        this.processError = responseObject.message;
                    } else {
                        this.redirectToPayment(responseObject);
                    }

                    this.loading = false;
                })
                .catch(err => {
                    this.handleTicketErrors(err.response.data);
                    this.loading = false;
                });
        } catch (error) {
            console.log('Unable to make post request');
            this.loading = false;
        }
    },
    verifyCompany() {
        try {
            this.loading = true;

            const formData = {
                searchVat: this.searchVat,
            };

            axios
                .post(window.route('hub-product.verify-company'), formData, {
                    headers: {
                        Accept: 'application/json',
                        'X-CSRF-TOKEN': this.token.content,
                    },
                })
                .then(response => {
                    const responseObject = response.data;

                    if (responseObject.message) {
                        this.companyName = responseObject.message?.company_name;
                        this.companyRegistrationNumber = responseObject.message?.company_registration_number;
                        this.companyLocality =
                            responseObject.message?.company_locality + ' ' + responseObject.message?.company_county;
                        this.companyAddress = responseObject.message?.company_address;
                    }

                    this.loading = false;
                })
                .catch(err => {
                    console.log(err.response.data);
                    this.loading = false;
                });
        } catch (error) {
            console.log('Unable to make post request');
            this.loading = false;
        }
    },
    validateForms() {
        try {
            this.errors = {};

            if (this.selectedPaymentType === 'private_person') {
                // Validate fields and add errors to the errors object with descriptive keys
                if (!this.privatePersonName?.trim()) {
                    this.errors.privatePersonName = true;
                }

                if (!this.privatePersonAddress?.trim()) {
                    this.errors.privatePersonAddress = true;
                }

                if (!this.privatePersonEmail?.trim()) {
                    this.errors.privatePersonEmail = true;
                }

                if (!this.privatePersonCi?.trim()) {
                    this.errors.privatePersonCi = true;
                }
            } else {
                // Validate fields and add errors to the errors object with descriptive keys
                if (!this.companyEmail?.trim()) {
                    this.errors.companyEmail = true;
                }

                if (!this.searchVat?.trim()) {
                    this.errors.searchVat = true;
                }
            }
        } catch (err) {}
    },
    redirectToPayment(response) {
        try {
            // Create a form element
            let form = document.createElement('form');
            form.method = 'POST';
            form.action = response.form.payment_url;
            form.target = '_self'; // Ensures it opens in the same window

            // Create and append hidden input fields
            let dataInput = document.createElement('input');
            dataInput.type = 'hidden';
            dataInput.name = 'data';
            dataInput.value = response.form.data;
            form.appendChild(dataInput);

            let envKeyInput = document.createElement('input');
            envKeyInput.type = 'hidden';
            envKeyInput.name = 'env_key';
            envKeyInput.value = response.form.env_key;
            form.appendChild(envKeyInput);

            // Append the form to the body and submit
            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.log('Unable to redirect to payment page', error);
        }
    },
    handleTicketErrors(response) {
        try {
            if (response.errors) {
                const errors = response.errors;
                let y = 0;

                for (let i = 1; i <= this.slots; i++) {
                    let nameKey = 'tickets.' + y + '.name';
                    let emailKey = 'tickets.' + y + '.email';
                    let phoneKey = 'tickets.' + y + '.phone';

                    if (this.hasKey(errors, nameKey)) {
                        this.addTicketValidationMessages(errors, i, nameKey, 'name');
                    }
                    if (this.hasKey(errors, emailKey)) {
                        this.addTicketValidationMessages(errors, i, emailKey, 'email');
                    }
                    if (this.hasKey(errors, phoneKey)) {
                        this.addTicketValidationMessages(errors, i, phoneKey, 'phone');
                    }

                    y++;
                }
            }
        } catch (err) {}
    },
    clearTicketFormErrors() {
        try {
            this.processError = null;
            let y = 0;

            for (let i = 1; i <= this.slots; i++) {
                this.removeTicketValidationMessages(i, 'name');
                this.removeTicketValidationMessages(i, 'email');
                this.removeTicketValidationMessages(i, 'phone');

                y++;
            }
        } catch (err) {}
    },
    hasKey(object, key) {
        return key in object;
    },
    addTicketValidationMessages(errors, keyCheck, key, elementName) {
        try {
            var elementID = `${elementName}-${keyCheck}`;
            const targetElement = document.getElementById(elementID);

            const label = document.createElement('label');
            label.textContent = errors[key];
            label.className = 'pl-2 pt-1 text-sm text-red';
            label.id = elementID + '-error';

            targetElement.insertAdjacentElement('afterend', label);
        } catch (err) {}
    },
    removeTicketValidationMessages(keyCheck, elementName) {
        try {
            var elementID = `${elementName}-${keyCheck}-error`;
            const targetElement = document.getElementById(elementID);

            if (targetElement) {
                targetElement.remove();
            }
        } catch (err) {}
    },
});
