import Cookies from 'js-cookie';
import set from 'lodash.set';
import tippy, { sticky, hideAll } from 'tippy.js';

export default $wire => ({
    init() {
        const self = this;

        const showOptionsTooltip = Cookies.get('show_create_alert_options_tooltip');
        const hideTooltip = Cookies.get('hideCreateAlertButtonToolTip');

        const buttonContainer = self.$refs.container;
        const toolTipContent = self.$refs.tooltip ?? null;
        const userBrowserAlertCount = buttonContainer.dataset.user_browser_alert_count;
        const userBrowserAlertWarningText = buttonContainer.dataset.user_browser_alert_warning_text;
        const userBrowserAlertWarningTitle = buttonContainer.dataset.user_browser_alert_warning_title;

        if (hideTooltip !== 'yes' && !!toolTipContent) {
            tippy(buttonContainer, {
                content: toolTipContent.innerHTML,
                allowHTML: true,
                appendTo: buttonContainer,
                sticky: true,
                trigger: 'manual',
                placement: 'bottom-end',
                plugins: [sticky],
                showOnCreate: true,
                delay: 400,
                interactive: true,
                hideOnClick: 'toggle',
                zIndex: 100,
                onShow(instance) {
                    instance.popper.querySelector('#closeCreateAlertButtonTooltip').addEventListener('click', () => {
                        instance.hide();
                        Cookies.set('hideCreateAlertButtonToolTip', 'yes', { expires: 3650 });
                    });
                },
            });
        }

        window.addEventListener('load', () => {
            const showOptionsDoneTooltip = Cookies.get('show_create_alert_options_tooltip_done');

            if (showOptionsTooltip == 'yes' && showOptionsDoneTooltip != 'yes') {
                var inThirtySixSeconds = new Date(new Date().getTime() + 36 * 1000);
                Cookies.set('show_create_alert_options_tooltip_done', 'yes', { expires: inThirtySixSeconds });
                self.showAlertUpdateDialog();
            }
        });

        Livewire.on('listingAlertCreatedEvent', () => {
            setTimeout(() => {
                self.showAlertUpdateDialog();
            }, 100);
        });

        Livewire.on('submitUpdateAlert', data => {
            const isChannelBrowser = document.getElementsByName('is_channel_browser')[0].checked;
            const isChannelEmail = document.getElementsByName('is_channel_email')[0].checked;

            if (
                // Options should have been changed from the starting state
                !(isChannelEmail === true && isChannelBrowser === false) &&
                self.hasOneSignal()
            ) {
                if (isChannelBrowser) {
                    window.OneSignal.push(function () {
                        window.OneSignal.getNotificationPermission().then(function (permission) {
                            if (permission !== 'granted') {
                                window.OneSignal.showNativePrompt();
                            } else {
                                window.OneSignal.registerForPushNotifications().then(() => {
                                    window.OneSignal.getSubscription(function (isSubscribed) {
                                        if (!isSubscribed) {
                                            window.OneSignal.setSubscription(true).then(function () {
                                                self.submitAlertUpdates();
                                            });
                                        } else {
                                            self.submitAlertUpdates();
                                        }
                                    });
                                });
                            }
                        });
                    });
                }
            } else {
                // only send an update to api if its not the default value i.e. daily
                const selectedRadioButton = document.querySelector('input[type="radio"][name="frequency"]:checked');
                if (selectedRadioButton && 'hourly' !== selectedRadioButton.value) {
                    $wire.updateAlert();
                }
            }

            hideAll();
        });

        window.OneSignal = window.OneSignal || [];
        window.OneSignal.push(function () {
            window.OneSignal.on('subscriptionChange', function (isOneSignalSubbed) {
                if (isOneSignalSubbed) {
                    window.OneSignal.getUserId().then(userId => {
                        if (userId) {
                            $wire.addOnesignalPlayerId(userId);
                        }
                    });
                } else {
                    window.OneSignal.getUserId().then(userId => {
                        if (userId) {
                            if (userBrowserAlertCount > 0) {
                                let notificationEvent = new CustomEvent('notification-card', {
                                    detail: {
                                        title: userBrowserAlertWarningTitle,
                                        type: 'success',
                                        content: userBrowserAlertWarningText,
                                    },
                                });
                                document.dispatchEvent(notificationEvent);
                            }

                            $wire.deleteOnesignalPlayerId(userId);
                        }
                    });
                }
            });
        });

        self.atTop = false;
    },

    hasOneSignal() {
        return (
            window.OneSignal &&
            typeof window.OneSignal.isPushNotificationsSupported != 'undefined' &&
            window.OneSignal.isPushNotificationsSupported()
        );
    },

    submitAlertUpdates() {
        window.OneSignal.getUserId().then(userId => {
            $wire.set('one_signal_id', userId, true);
            $wire.updateAlert();
        });
    },

    showAlertUpdateDialog() {
        const self = this;
        const buttonContainer = self.$refs.container;

        if (self.hasOneSignal()) {
            window.OneSignal.push(function () {
                window.OneSignal.on('notificationPermissionChange', function (permissionChange) {
                    if (permissionChange && permissionChange.to && permissionChange.to === 'granted') {
                        window.OneSignal.setSubscription(true).then(function () {
                            self.submitAlertUpdates();
                        });
                    }
                });
            });

            hideAll();
            tippy(buttonContainer, {
                content: () => document.getElementById('alerttooltipoptions'),
                allowHTML: true,
                appendTo: buttonContainer,
                sticky: true,
                trigger: 'manual',
                placement: 'bottom',
                plugins: [sticky],
                showOnCreate: true,
                delay: 200,
                interactive: true,
                hideOnClick: 'toggle',
                zIndex: 100,
                onShow(instance) {
                    instance.popper.querySelector('#closeOptionsAlertButtonTooltip').addEventListener('click', () => {
                        instance.hide();
                    });
                },
            });
        }
    },

    getSubscriptionState() {
        return Promise.all([window.OneSignal.isPushNotificationsEnabled(), window.OneSignal.isOptedOut()]).then(
            result => ({
                isPushEnabled: result[0],
                isOptedOut: result[1],
            }),
        );
    },

    /*
     * This method is used to create the alert from the local storage after the user has logged in
     */
    createAlertFromSavedAction($wire, link) {
        if (localStorage.getItem('storePendingAlert') === JSON.stringify(link)) {
            // ensure the alert is the same as the one stored in the local storage
            $wire.createAlert();
            localStorage.removeItem('storePendingAlert');
        }
    },

    createListingAlert() {
        $wire.createAlert(window.location.href);
    },

    saveAlertAction(link) {
        $wire.saveAlertAction(window.location.href);
        localStorage.setItem('storePendingAlert', JSON.stringify(link));
    },
});
