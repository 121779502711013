import axios from 'axios';

export default ({ projectSearchRoute, clickOutside, searchRoute }) => ({
    clickOutside: clickOutside ?? false,
    loading: false,
    projectSearchRoute: projectSearchRoute ?? '',
    searchRoute: searchRoute ?? '',
    projectName: '',
    selectedProjectValue: '',
    locationId: 0,
    showProjectResults: false,
    hasProjectSearchResults: false,
    projectSearchResults: [],

    init() {},
    resetFilters() {
        this.projectName = '';
        this.projectSearchResults = [];
        this.showProjectResults = false;
        this.hasProjectSearchResults = false;
    },
    async searchProject() {
        this.selectedProjectValue = '';

        if (!this.projectName || this.projectName.length < 3) {
            this.projectSearchResults = [];
            this.hasProjectSearchResults = false;

            return;
        }

        this.loading = true;
        let data = null;

        let response = await axios.get(this.projectSearchRoute, {
            params: {
                name: this.projectName,
                location: this.locationId,
            },
        });

        data = response.data;
        this.hasProjectSearchResults = Object.keys(data).length > 0;

        this.loading = false;
        this.projectSearchResults = data;
        this.showProjectResults = true;
    },
    selectedProject(name, value) {
        this.selectedProjectValue = value;
        this.projectName = name;
        this.projectSearchResults = [];
        this.hasProjectSearchResults = false;
        this.showProjectResults = false;
    },
    async redirectToSearchResults() {
        this.loading = true;

        let response = await axios.get(this.searchRoute, {
            params: {
                selectedProject: this.selectedProjectValue,
                projectName: this.projectName,
                location: this.locationId,
                search_type: 'search_projects',
            },
        });

        this.loading = false;

        if (response) {
            window.open(response.data, '_blank');
        }
    },
    selectProjectLocation(locationId) {
        this.locationId = locationId;
    },
});
