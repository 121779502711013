import { fromUnixTime, formatDistance } from 'date-fns';
import '@resources/js/app/composables/visibility.min.js';
import '@resources/js/app/composables/socket.js';
import Chat from '../chat';
import { ro } from 'date-fns/locale';

export default ($wire, isOpen, context = 'global') => ({
    userId: $wire.get('agentId'),
    token: $wire.get('token'),
    chatUrl: $wire.get('chatUrl'),
    conversations: $wire.entangle('conversations').live,
    conversation_id: $wire.entangle('conversation_id').live,
    conversation: $wire.entangle('conversation').live,
    listingId: $wire.get('listingId'),
    translations: $wire.get('translations'),
    defaultMessages: $wire.get('defaultMessages'),
    newMessageText: $wire.entangle('newMessageText'),
    activeConversationId: null,

    chatContainer: null,
    chatInitialized: false,
    chatLoaded: false,
    client: null,
    messages: [],
    statusOnlineValue: '',
    typingValue: '',
    oldTypingStatus: 'stop',
    chatUnavailable: '',
    buttonDisabled: false,
    allowEnable: false,
    chatBlockedMessage: '',
    historyLeft: false,
    scrollActionPerformed: false,
    lastScrollHeight: 0,

    init() {
        let self = this;
        this.chatContainer = self.$refs.ChatContainer;

        if (typeof isOpen != 'undefined') {
            self.$watch('isOpen', value => {
                if (value) {
                    self.initChat();
                }
            });
        } else {
            self.initChat();
        }
    },

    initChat() {
        let self = this;

        if (self.chatInitialized) {
            return true;
        }
        self.chatInitialized = true;

        if (!Alpine.store('globals').chatClient) {
            self.client = new Chat({
                token: self.token,
                chatConnectUrl: self.chatUrl,
                location: 'portal',
                agentId: self.userId,
            });
            Alpine.store('globals').chatClient = self.client;

            self.client.connect();
        } else {
            self.client = Alpine.store('globals').chatClient;
            if (self.client.socket.connected) {
                self.joinConversation(self);
            }
        }

        self.client.on('authenticated', async () => {
            self.joinConversation(self);
        });
    },

    async joinConversation(self) {
        await self.join(self.getSelectedConversation());

        self.chatUnavailable = '';
        self.buttonDisabled = false;
        self.chatLoaded = true;

        self.client.on('chat', async response => {
            let message = response.data.message;
            message.statusMessage = '';
            if (self.conversation?.id == null) {
                self.conversation = {};
                self.conversation.id = message.conversationId;
            }
            self.messages.push(self.formatMessage(message));
            if (message.userId != self.userId) {
                self.client.externalEmit('delivered', { _id: message._id });
                self.setDocumentTitleUnread();
                if (!Visibility.hidden()) {
                    self.markRead();
                }
            } else {
                self.newMessageText = '';
            }

            self.scrollToBottom();
        });

        self.client.on('disconnect', async response => {
            self.chatUnavailable = $wire.translations['chat_unavailable'];
            self.buttonDisabled = true;
        });

        self.client.on('markRead', async response => {
            if (response.status != 'success') {
                return;
            }

            for (let message of self.messages) {
                if (self.userId == message.userId) {
                    message.statusMessage = 'read';
                }
            }
        });

        self.client.on('history', async response => {
            if (typeof response.data.messages !== 'undefined') {
                const loadHistory = response.data.type == 'prepend';
                let newMessages = [...response.data.messages].map(message => self.formatMessage(message));

                if (loadHistory) {
                    newMessages = newMessages.reverse().concat(self.messages);
                }

                newMessages.forEach((message, index) => {
                    if (index === 0 || message.chatDate !== newMessages[index - 1].chatDate) {
                        message.displayChatDate = message.chatDate;
                    } else {
                        message.displayChatDate = null;
                    }
                });

                self.messages = newMessages;
                self.scrollActionPerformed = false;
                self.historyLeft = response.data.historyLeft === true;

                if (!loadHistory) {
                    self.scrollToBottom();
                } else {
                    setTimeout(() => {
                        self.scrollTo(self.getScrollHeight() - self.lastScrollHeight);
                    }, 100);
                }

                self.markRead();
                self.conversation.counter = null;
            }
        });

        self.client.on('delivered', async response => {
            if (response.status != 'success') {
                return;
            }

            let id = response.data._id;

            let objIndex = self.messages.findIndex(message => message.id == id);
            if (typeof self.messages[objIndex] != 'undefined' && self.userId == self.messages[objIndex].userId) {
                self.messages[objIndex].statusMessage = 'delivered';
            }
        });

        self.client.on('typingStatus', async response => {
            if (response.status != 'success') {
                return;
            }

            var data = response.data;

            if (data.user.id != self.userId) {
                if (data.typing == 'start') {
                    self.typingValue = data.user.name + $wire.translations['typing'];
                } else {
                    self.typingValue = '';
                }
            }
        });

        self.client.on('joinConversation', async response => {
            if (response.status != 'success') {
                return;
            }

            self.chatDateDisplayed = [];

            if (self.conversation?.id == null) {
                self.conversation = response.data.conversation;
            }

            if (response.data.conversation?.blocked) {
                self.conversation.blocked = true;
                self.conversation.blockedBy = response.data.conversation?.blockedBy;
                self.chatBlocked(self.userId === self.conversation.blockedBy);
            } else {
                self.chatUnblocked();
            }
        });

        self.client.on('leaveConversation', async response => {
            // TODO: show the participant leaving
        });

        self.client.on('block', async response => {
            if (response.data.conversation?.blocked) {
                self.conversation.blocked = true;
                self.conversation.blockedBy = response.data.conversation?.blockedBy;
                self.chatBlocked(self.userId === response.data.conversation?.blockedBy);
            } else {
                self.conversation.blocked = false;
                self.chatUnblocked();
            }
        });

        self.client.on('presence', async response => {
            if (response.status != 'success') {
                return;
            }

            var data = response.data;
            if (data.userId != self.userId) {
                self.statusOnlineValue = data.status;
            }
        });

        self.scrollToBottom();

        const scrollableElement = self.chatContainer.querySelector('.scroll-container');
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', function () {
                if (scrollableElement.scrollTop < scrollableElement.scrollHeight * 0.1) {
                    if (!self.scrollActionPerformed && self.historyLeft) {
                        self.lastScrollHeight = scrollableElement.scrollHeight;
                        self.loadMore();
                    }
                    self.scrollActionPerformed = true;
                }
            });
        }
    },

    getSelectedConversation() {
        let results = this.conversations.find(conversation => conversation.id === this.conversation_id);
        if (results) {
            return results;
        }

        if (this.conversations.length > 0) {
            return this.conversations[0];
        }

        if (this.conversation?.id != null) {
            return this.conversation;
        }
        return null;
    },

    formatTimestamp(timestamp) {
        return formatDistance(fromUnixTime(timestamp), new Date(), { addSuffix: true, locale: ro });
    },

    formatMessageTimestamp(timestamp) {
        return formatDistance(fromUnixTime(timestamp), new Date(), { addSuffix: true });
    },

    async join(conversation) {
        this.messages = [];
        if (conversation == null) {
            this.client.join({ offerId: String(this.listingId) });
            this.messages = this.defaultMessages;
        } else {
            this.conversation = conversation;
            let event = new CustomEvent('conversation-load', {
                detail: {
                    conversation: conversation,
                },
            });
            window.dispatchEvent(event);
            this.client.join({ conversationId: conversation.id });
            this.activeConversationId = conversation.id;
        }

        // fetch history from the socket server in case it's more up-to-date than mongo
        // this appears to crash the socket server for reasons that aren't explained
        // this.client.history(conversation.id);
    },

    send() {
        if (this.stringIsEmpty(this.newMessageText)) {
            return false;
        }
        let data = '';
        if (this.conversation?.id) {
            data = { conversationId: this.conversation.id };
        } else {
            data = { offerId: this.listingId };
        }
        data.body = this.newMessageText;
        this.client.send(data);
        this.newMessageText = '';
        this.typingStatus('');
        this.scrollToBottom();
    },

    loadMore() {
        if (this.conversation?.id == null) {
            return;
        }

        const timestamp = this.messages[0].timestamp;
        this.client.externalEmit('history', { conversationId: this.conversation.id, timestamp: timestamp });
    },

    async typingStatus(actualTypingText) {
        var actualTypingStatus = 'start';
        if (actualTypingText == '') {
            actualTypingStatus = 'stop';
        }

        if (this.oldTypingStatus != actualTypingStatus && this.conversation) {
            this.client.externalEmit('typingStatus', {
                conversationId: this.conversation.id,
                typing: actualTypingStatus,
            });
            this.oldTypingStatus = actualTypingStatus;
        }
    },

    async chatBlocked(blockedByMyself) {
        if (blockedByMyself) {
            this.chatBlockedMessage = $wire.translations['conversation_blocked_user'];
            this.allowEnable = true;
        } else {
            this.chatBlockedMessage = $wire.translations['conversation_blocked'];
            this.allowEnable = false;
        }
        this.buttonDisabled = true;
    },

    toggleBlockConversation() {
        let action = 'block';

        if (this.conversation.blocked && this.userId === this.conversation.blockedBy) {
            action = 'unblock';
        }

        this.client.externalEmit('block', { conversationId: this.conversation.id, mark: action });
    },

    setDocumentTitleUnread() {
        if (document.title.substring(0, 14) != '1 mesaj nou - ') {
            document.title = '1 mesaj nou - ' + document.title;
        }
    },

    async markRead() {
        if (this.conversation?.id) {
            document.title = document.title.replace('1 mesaj nou - ', '');
            this.client.externalEmit('markRead', { conversationId: this.conversation.id });
        }
    },

    async chatUnblocked() {
        this.chatBlockedMessage = '';
        this.buttonDisabled = false;
    },

    timestampToTime(timestamp) {
        let date = new Date(timestamp);
        // Hours part from the timestamp
        let hours = date.getHours();
        if (hours < 10) {
            hours = '0' + hours;
        }
        // Minutes part from the timestamp
        let minutes = date.getMinutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return hours + ':' + minutes;
    },

    formatMessage(message) {
        let statusMessage = 'sent';
        if (message.userId == this.userId) {
            if (message.delivered.length > 0) {
                statusMessage = 'delivered';
            }
            if (message.seen.length > 0) {
                statusMessage = 'read';
            }
        }

        let chatDate = this.timestampToChatDate(message.timestamp);

        return {
            key: message._id + context,
            id: message._id,
            userId: message.userId,
            statusMessage: statusMessage,
            conversationId: message.conversationId,
            body: message.body,
            timestamp: message.timestamp,
            seen: message.seen,
            delivered: message.delivered,
            me: message.userId === this.userId,
            timestampFormatted: this.timestampToTime(message.timestamp),
            chatDate: chatDate,
        };
    },

    formatConversation(conversation) {
        return (conversation = $wire.formatConversation(conversation));
    },

    truncateText(text) {
        return '"' + text.substring(0, 28);
    },

    scrollToBottom() {
        this.scrollTo(-1, 100);
    },

    scrollTo(val, timeout = 0) {
        let self = this;
        setTimeout(() => {
            if (val == -1) {
                val = this.getScrollHeight();
            }

            const scrollContainer = self.chatContainer.querySelector('.scroll-container');
            if (scrollContainer) {
                scrollContainer.scrollTop = val;
            }
        }, timeout);
    },

    getScrollHeight() {
        return this.chatContainer.querySelector('.scroll-container')?.scrollHeight ?? 0;
    },

    timestampToChatDate(timestamp) {
        var now = new Date();
        var date = new Date(timestamp);

        var day1 = now.getDate();
        var month1 = now.getMonth() + 1;
        var year1 = now.getFullYear();
        var today = day1 + ' ' + month1 + ' ' + year1;

        var day2 = date.getDate();
        var month2 = date.getMonth() + 1;
        var year2 = date.getFullYear();
        var inputDate = day2 + ' ' + month2 + ' ' + year2;

        if (today === inputDate) {
            return $wire.translations['today'];
        } else if (year1 === year2) {
            return day2 + ' ' + $wire.translations['months'][month2];
        } else {
            return day2 + ' ' + $wire.translations['months'][month2] + ' ' + year2;
        }
    },

    setMessage(message) {
        this.newMessageText = message;
    },

    stringIsEmpty(str) {
        return !str.trim();
    },
});
