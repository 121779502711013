import axios from 'axios';

export default ({ defaultCategory, offerType, searchRoute, categoriesForSale, categoriesForRent, locationId }) => ({
    defaultCategory: defaultCategory ?? '',
    offerType: offerType ?? '',
    searchRoute: searchRoute ?? '',
    locationId: locationId ?? 0,
    categoryFilter: '',
    projectName: '',
    loading: false,
    active: 'search_listings',
    showNumberOfProperties: false,
    categoriesForSale: categoriesForSale ?? [],
    categoriesForRent: categoriesForRent ?? [],

    init() {
        this.categoryFilter = this.defaultCategory;
        this.enableNumberOfProperties();
    },
    async submit() {
        this.loading = true;

        let response = await axios.get(this.searchRoute, {
            params: {
                offer_type: this.offerType,
                category: this.categoryFilter,
                search_type: this.active,
                location: this.locationId,
            },
        });

        this.loading = false;

        if (response) {
            window.open(response.data, '_self');
        }
    },
    isActive(tab) {
        return tab === this.active;
    },
    setActive(value) {
        this.active = value;
        this.showNumberOfProperties = false;
        this.enableNumberOfProperties();

        if (this.active === 'search_projects') {
            this.categoryFilter = '';
        }

        if (this.active === 'search_listings') {
            this.offerType = 'sell';
        }

        if (this.active !== 'search_projects') {
            let categories = offerType === 'sale' ? this.categoriesForSale : this.categoriesForRent;
            let result = categories.find(category => category.value == this.categoryFilter);

            if (!result) {
                this.categoryFilter = '';
            }
        }
    },
    enableNumberOfProperties() {
        if (this.active === 'search_listings' || this.active === 'search_international') {
            this.showNumberOfProperties = true;
        }
    },
    changeCategory(category) {
        this.categoryFilter = category;
    },
    changeOfferType(offerType) {
        this.offerType = offerType;

        let categories = offerType === 'sale' ? this.categoriesForSale : this.categoriesForRent;
        let result = categories.find(category => category.value == this.categoryFilter);

        if (!result) {
            this.categoryFilter = '';
        }
    },
    selectLocation(locationId) {
        this.locationId = locationId;
    },
});
