import Cookies from 'js-cookie';

export default ($el, url) => ({
    init() {
        this.appendGAClientIdAndFbkCookiesToChatLink();
    },
    checkIfGoogleAnalyticsIsReady(repeatCheckTimeout = null) {
        if (typeof window.ga === 'function' && typeof window.ga.getAll === 'function') {
            return true;
        } else if (typeof repeatCheckTimeout == 'number') {
            this.waitUntilGoogleAnalyticsIsReady(repeatCheckTimeout);
        }
        return false;
    },
    waitUntilGoogleAnalyticsIsReady(timeout = 500) {
        return setTimeout(() => this.checkIfGoogleAnalyticsIsReady(), timeout);
    },
    getGoogleAnalyticsClientId() {
        return window.ga.getAll()[0].get('clientId');
    },
    appendGAClientIdAndFbkCookiesToChatLink() {
        if (this.checkIfGoogleAnalyticsIsReady(500)) {
            $el.href += !this.urlHasQueryString(url) ? '?' : '&' + 'ga-client-id=' + this.getGoogleAnalyticsClientId();
        }
        $el.href +=
            !this.urlContainsQueryString(url, 'fbp') && Cookies.get('_fbp')
                ? !this.urlHasQueryString(url)
                    ? '?'
                    : '&' + 'fbp=' + Cookies.get('_fbp')
                : '';
        $el.href +=
            !this.urlContainsQueryString(url, 'fbc') && Cookies.get('_fbc')
                ? !this.urlHasQueryString(url)
                    ? '?'
                    : '&' + 'fbc=' + Cookies.get('_fbp')
                : '';
    },
    urlHasQueryString(url) {
        url = new URL(url);
        return url.search !== '';
    },
    urlContainsQueryString(url, queryString) {
        return url.indexOf(queryString + '=') > 0;
    },
});
