export default () => ({
    search: window.AdvanceSearchComponent(),

    init() {
        document.addEventListener('open:refine-search', event => {
            this.open();
        });
    },

    open() {
        this.search.open();
    },

    close() {
        this.search.close();
    },
});
