export default ($wire, $dispatch) => ({
    openAuthModal(popUpData) {
        $dispatch('open-pop-up-auth', [popUpData]);
    },
    enquire() {
        $wire.submitEnquiry();
    },
    finish() {
        $dispatch('finish-enquiry');
    },
});
