import Cookies from 'js-cookie';

export default ({ domain, name, ttl, secure, sameSite }) => ({
    init: function () {
        if (!this.cookieExists()) {
            this.setVisitorId();
        }
    },

    cookieExists: function () {
        return typeof Cookies.get(name) !== 'undefined';
    },

    generateVisitorUuid: function () {
        let dt = new Date().getTime();

        return (
            name +
            '-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);

                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
            })
        );
    },

    setVisitorId: function () {
        Cookies.set(name, this.generateVisitorUuid(), {
            expires: ttl,
            domain: domain,
            secure: secure,
            sameSite: sameSite,
        });
    },
});
