export default $wire => ({
    value: $wire.entangle('value').live,
    fieldName: $wire.fieldName,

    isSelected(optionValue) {
        return Object.values(this.value).includes(optionValue);
    },

    selectValue(optionValue) {
        if (!this.isSelected(optionValue)) {
            this.value = [...Object.values(this.value), optionValue];
            $wire.dispatch('fieldChanged', { field: this.fieldName, value: this.value });
        }
    },

    removeValue(optionValue) {
        this.value = Object.values(this.value).filter(value => value !== optionValue);
        $wire.dispatch('fieldChanged', { field: this.fieldName, value: this.value });
    },
});
