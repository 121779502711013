import intlTelInput from 'intl-tel-input';

export default ({ container, dispatch, initialCountry }) => ({
    telInput: undefined,
    country: initialCountry,

    init() {
        const input = container.querySelector('input');

        if (!input) {
            throw new Error('Input field is required');
        }

        this.telInput = intlTelInput(input, {
            initialCountry,
        });

        this.setCountry(initialCountry);

        input.addEventListener('countrychange', () => this.onChange());
    },

    onChange() {
        let selectedCountry = this.telInput.getSelectedCountryData().iso2.toUpperCase();

        this.country = selectedCountry;

        this.setCountry(selectedCountry);
    },

    change(value) {
        this.telInput.setCountry(value);
    },

    setCountry(country) {
        dispatch('set-country', country);
    },
});
