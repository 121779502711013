import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

export default ($wire, { modalEventName }) => ({
    isOpen: false,
    modalBody: null,
    state: 'unread',
    btnReadMore: null,

    init() {
        const self = this;
        self.modalBody = document.querySelector('#perfect-scrollbar-wrapper');
        self.btnReadMore = document.querySelector('#read-more');

        const ps = new PerfectScrollbar(self.modalBody, {
            wheelSpeed: 1,
            wheelPropagation: false,
            suppressScrollX: false,
        });

        self.checkScrollReachEnd = self.checkScrollReachEnd.bind(self);
        self.modalBody.addEventListener('ps-scroll-y', self.checkScrollReachEnd);
        self.modalBody.addEventListener('ps-y-reach-end', e => {
            if (this.state != 'agreed') {
                this.state = 'read';
            }
        });

        self.btnReadMore.addEventListener('click', function () {
            self.modalBody.scrollTo({
                top: self.modalBody.scrollHeight - 100,
            });
        });

        window.addEventListener(modalEventName, event => {
            event.detail === 'open' ? self.open() : self.close();
        });
    },

    // Patch to fix the issue with the 'ps-y-reach-end' event not firing when page is zoomed in/out
    checkScrollReachEnd(e) {
        const scrollHeight = this.modalBody.scrollHeight;
        const containerHeight = this.modalBody.clientHeight;
        const scrollTop = this.modalBody.scrollTop;
        if (scrollTop + containerHeight >= scrollHeight - 100) {
            this.modalBody.dispatchEvent(new Event('ps-y-reach-end'));
            this.modalBody.removeEventListener('ps-scroll-y', this.checkScrollReachEnd);
        }
    },
    open() {
        this.isOpen = true;
        document.body.style.overflow = 'hidden';
    },
    close() {
        this.isOpen = false;
        document.body.style.overflow = 'auto';
    },
    agree() {
        // TODO
        // -check checkbox
        // $.post("/contul-meu/actiune-vizualizare-politica-confidentialitate", function(data, status){});
        $wire.dispatch('acceptTerms');
        this.state = 'agreed';
        this.close();
    },
});
