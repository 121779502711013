export default $wire => ({
    loading: false,
    isOpen: $wire.entangle('isOpen').live,
    mode: $wire.entangle('mode').live,
    showOtpSuccessScreen: false,
    open($event) {
        $wire.open($event.detail);
    },
    close() {
        this.isOpen = false;
    },
    promptMobileOTP() {
        this.mode = 'otp';
        this.loading = false;
    },
    get mobileOTPScreenVisible() {
        return this.mode === 'otp' && this.loading === false;
    },
    get showLoginForm() {
        return this.mode === 'login' && this.loading === false;
    },
    get showRegisterForm() {
        return this.mode === 'register' && this.loading === false;
    },
    finishProcessing(ttl) {
        const self = this;
        setTimeout(function () {
            self.loading = false;
        }, ttl);
    },
    startProcessing() {
        this.loading = true;
    },
    otpVerified() {
        const self = this;
        setTimeout(function () {
            self.showOtpSuccessScreen = true;
        }, 1000);
    },
});
