export default $wire => ({
    value: $wire.get('value') || [],
    options: $wire.get('options') || [],
    emitEvent: $wire.get('emitEvent') || null,
    emitEventTo: $wire.get('emitEventTo') || null,
    fieldName: $wire.fieldName,
    open: false,

    selectOption(option) {
        if (!this.isSelected(option)) {
            this.value.push(option);
            this.triggerEvents(this.fieldName, this.value);
        }
    },

    removeOption(option) {
        this.value = this.value.filter(item => item !== option);
        this.triggerEvents(this.fieldName, this.value);
    },

    toggleDropdown() {
        this.open = !this.open;
    },

    isSelected(option) {
        return this.value.includes(option);
    },

    clearSelections() {
        this.value = [];
        this.triggerEvents(this.fieldName, this.value);
    },

    optionByValue(value) {
        return this.options.find(option => option.value === value);
    },

    triggerEvents(fieldName, value) {
        if (this.emitEvent) {
            if (this.emitEventTo) {
                $wire.dispatchTo(this.emitEventTo, this.emitEvent, [value]);
            } else {
                $wire.dispatch(this.emitEvent, [value]);
            }
        } else {
            $wire.dispatch('fieldChanged', { field: fieldName, value: value });
        }
    },

    selectedLabel(sufix) {
        let labels = '';

        this.value.forEach((item, index) => {
            this.options.forEach(option => {
                if (option.value === item) {
                    labels += option.label;
                }
            });

            if (index < this.value.length - 1) {
                labels += ', ';
            }
        });

        if (labels != '') {
            labels += ' ' + sufix;
        }

        return labels;
    },
});
