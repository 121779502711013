export default selector => ({
    init() {
        let container = document.querySelector(selector);
        let isDragging = false;
        let lastClientX = null;
        let scrollTimeout = null;

        const handleMouseMove = e => {
            if (isDragging && lastClientX !== null) {
                let delta = e.clientX - lastClientX;
                container.scrollLeft -= delta;
                lastClientX = e.clientX;
            }
        };

        const handleWheel = e => {
            e.preventDefault();
            if (e.deltaY !== 0) {
                container.scrollLeft += e.deltaY;
            }
        };

        if (container !== null) {
            container.addEventListener('mousedown', function (e) {
                isDragging = true;
                lastClientX = e.clientX;
            });

            container.addEventListener('mouseup', function () {
                isDragging = false;
                lastClientX = null;
            });

            container.addEventListener('mouseenter', function () {
                scrollTimeout = setTimeout(() => {
                    container.addEventListener('mousemove', handleMouseMove);
                    container.addEventListener('wheel', handleWheel);
                }, 200);
            });

            container.addEventListener('mouseleave', function () {
                clearTimeout(scrollTimeout);
                container.removeEventListener('mousemove', handleMouseMove);
                container.removeEventListener('wheel', handleWheel);
            });
        }
    },
});
