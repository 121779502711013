export default ($wire, { enquiryData, phoneUnmaskPopup, requestContactOnEnquiry, lineClamp }) => ({
    displayReadMore: false,
    canReadMore: $wire.entangle('canReadMore').live,
    phoneNumberVisible: $wire.entangle('phoneNumberVisible').live,

    init() {
        const visibleHeight = document.querySelector('#truncatedDescription').offsetHeight;
        const scrollHeight = document.querySelector('#truncatedDescription').scrollHeight;
        if (scrollHeight > visibleHeight) {
            this.displayReadMore = true;
            this.toggleReadMore(this.canReadMore);
        }
    },

    clampText(selector, lineCount = null) {
        const element = document.querySelector(selector);
        if (element) {
            if (lineCount) {
                // Get the line height to calculate maxHeight as a fallback
                const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
                const maxHeight = lineHeight * lineCount;

                // Apply both methods
                element.style.display = '-webkit-box';
                element.style.webkitBoxOrient = 'vertical'; // Required for -webkit-line-clamp
                element.style.webkitLineClamp = lineCount; // Enables the ellipsis for clamped lines
                element.style.overflow = 'hidden'; // Hide overflow
                element.style.maxHeight = `${maxHeight}px`; // Fallback to max height
            } else {
                // Reset styles when clamping is removed
                element.style.display = '';
                element.style.webkitBoxOrient = '';
                element.style.webkitLineClamp = '';
                element.style.overflow = '';
                element.style.maxHeight = 'none'; // Remove max height restriction
            }
        }
    },

    toggleReadMore(value = undefined) {
        if (typeof value == 'undefined') {
            this.canReadMore = !this.canReadMore;
        } else {
            this.canReadMore = value;
        }

        if (this.canReadMore) {
            this.clampText('#truncatedDescription', lineClamp);
        } else {
            this.clampText('#truncatedDescription');
        }
    },

    async handleDescriptionContactClick(contactType, contactKey) {
        if (contactType === 'phone' && phoneUnmaskPopup && requestContactOnEnquiry) {
            enquiryData['type'] = 'phone';
            $wire.dispatchTo('enquiry.agent-contact-enquiry-modal', 'wantsToEnquire', { data: enquiryData });
        } else {
            await $wire.handleDescriptionContactClick(contactType, contactKey);
        }
    },
});
