export default ($dispatch, { length = 4 }) => ({
    value: '',
    validation: /\d/g,
    inputRefPrefix: 'input',
    focussedPosition: 0,
    init() {
        this.$nextTick(() => {
            let firstInput = this.getInputByIndex(0);

            firstInput.focus();
        });

        window.addEventListener('otp-verification-failed', () => {
            this.reset();
        });
    },

    get isReadyForSubmission() {
        return this.value.length === length;
    },

    get isNeutral() {
        return this.isReadyForSubmission === false;
    },

    reset() {
        this.value = '';

        this.focussedPosition = 0;

        Array.from({ length }, (empty, index) => {
            this.$refs[`input-${index}`].value = '';
        });

        this.$refs[`input-0`].focus();
    },

    updateValue() {
        this.value = Array.from({ length: length }, (empty, index) => {
            return this.$refs[`input-${index}`].value || '';
        }).join('');

        $dispatch('pin-changed', this.value);

        $dispatch('pin-completed', this.isReadyForSubmission);
    },

    onInput(input, currentPosition) {
        let valueReceived = input.value;

        const validatedValue = valueReceived.match(this.validation);

        // if the input doesn't match our needs, don't do anything
        if (this.emptyInputValue(validatedValue)) {
            input.value = '';

            return;
        }

        input.value = validatedValue;

        this.updateValue();

        this.focusOnNextInput(currentPosition);
    },

    onBackSpace(pin, currentPosition) {
        if (pin.value) {
            return pin.value;
        }

        this.focusOnPreviousInput(currentPosition);

        this.updateValue();
    },

    onPaste(event, pasteFromPosition) {
        const text = event.clipboardData.getData('Text').match(this.validation);
        if (this.emptyInputValue(text)) {
            return;
        }

        // This filters only numbers, then slices based on how many inputs remain
        const remainingInputs = length - pasteFromPosition;
        const value = text.slice(0, remainingInputs).join('');

        // Figure out what inputs we need to update
        const inputsToUpdate = Array.from(Array(remainingInputs), (empty, index) => {
            return index + pasteFromPosition;
        }).splice(0, value.length);

        // Update the values
        inputsToUpdate.forEach((index, i) => {
            let currentInput = this.getInputByIndex(index);

            currentInput.value = value[i];
        });

        // Focus the last input we updated
        this.focusOnNextInput(inputsToUpdate.pop());
        this.updateValue();
    },

    focusOnNextInput(currentInputIndex) {
        let nexInputIndex = currentInputIndex + 1;

        // Only if there's a next input
        if (nexInputIndex < length) {
            this.focusOnInput(nexInputIndex);
        }
    },

    focusOnPreviousInput(currentInputIndex) {
        let previousInputIndex = currentInputIndex - 1;

        // If previous doesn't exist, focus the first
        if (previousInputIndex < 0) {
            previousInputIndex = 0;
        }

        this.focusOnInput(previousInputIndex);
    },

    focusOnInput(index) {
        let input = this.getInputByIndex(index);

        this.focussedPosition = index;

        input && input.focus();

        input && input.select();
    },

    emptyInputValue(inputValue) {
        return !inputValue || !inputValue.length;
    },

    getNumericIndexFromInputRef(ref) {
        return ref.match(/\d+/)[0];
    },

    getInputByIndex(index) {
        return this.$refs[`input-${index}`];
    },
});
