export default ({ modalEventName, hideOverflow = true, onCloseEvent = null, dispatch = null }) => ({
    isOpen: false,
    init() {
        window.addEventListener(modalEventName, event => {
            event.detail === 'open' ? this.open() : this.close();
        });

        document.addEventListener('keydown', event => {
            if (event.key === 'Escape') {
                this.close();
            }
        });
    },
    open() {
        this.isOpen = true;

        if (hideOverflow) {
            document.body.style.overflow = 'hidden';
        }
    },
    close() {
        this.isOpen = false;

        this.finish();
    },

    finish() {
        document.body.style.overflow = 'auto';

        if (onCloseEvent && dispatch) {
            dispatch(onCloseEvent);
        }
    },
});
