import Swiper, { Scrollbar } from 'swiper';
import 'swiper/css/scrollbar';

export default $wire => ({
    shown: false,
    hasMorePolePositions: $wire.entangle('hasMorePolePositions').live,
    page: 1,
    myThumbsSwiper: null,

    init() {
        let slider = this.$el.getElementsByClassName('swiper')[0];

        if (slider != null) {
            this.myThumbsSwiper = new Swiper(slider, {
                modules: [Scrollbar],
                slidesPerView: 'auto',
                slideToClickedSlide: true,
                initialSlide: 0,
                watchSlidesProgress: true,
                history: {
                    replaceState: true,
                },
                scrollbar: {
                    el: this.$el.getElementsByClassName('swiper-scrollbar')[0],
                    draggable: true,
                    dragSize: 50,
                },
                observer: true,
                threshold: 10,
                shortSwipes: false,
                longSwipesRatio: 0.1,
                longSwipesMs: 100,
            });
        }
    },

    loadMore() {
        if (this.hasMorePolePositions) {
            this.page++;
            $wire.dispatch('loadMorePolePositions', [this.page]);
        }
    },
});
