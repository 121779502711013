export default () => ({
    loading: false,

    startLoading: function () {
        this.loading = true;
    },

    startLoadingWithTimeout: function (delay = 5000) {
        let self = this;
        self.loading = true;
        setTimeout(function () {
            self.loading = false;
        }, delay);
    },

    stopLoading: function () {
        this.loading = false;
    },
});
