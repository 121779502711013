export default $dispatch => ({
    copyWithNotificationAndEvent(notification, event, url, shareMethod, itemId, ga4ItemId, ga4PrevItemId) {
        this.copy(this.$refs.inputWithText.value).then(() => {
            this.sendNotification(notification);
            this.sendEvent(event, url, shareMethod, itemId, ga4ItemId, ga4PrevItemId);
        });
    },

    copy(text) {
        if (navigator.clipboard) {
            return navigator.clipboard.writeText(text);
        }

        return this.fallbackCopy(text);
    },

    fallbackCopy(text) {
        return new Promise((resolve, reject) => {
            const textArea = document.createElement('textarea');
            textArea.value = text;

            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.position = 'fixed';

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                reject('Unable to copy to clipboard', err);
            }

            document.body.removeChild(textArea);

            resolve();
        });
    },

    sendNotification(notification) {
        $dispatch('notify', {
            message: notification,
        });
    },

    sendEvent(event, url, shareMethod, itemId, ga4ItemId, ga4PrevItemId) {
        if (window.dataLayer) {
            window.dataLayer.push({ event });
            Livewire.dispatch('shareListing', {
                url: url,
                method: shareMethod,
                itemId: itemId,
                ga4ItemId: ga4ItemId,
                ga4PrevItemId: ga4PrevItemId,
                openUrl: false,
            });
        }
    },
});
