export default (navigationButtons, autoPlay, showNavigation, mouseTogglesNavigation) => ({
    listingItemSize: 0,

    showNav: false,

    listingLeftIntersecting: true,

    listingRightIntersecting: false,

    autoPlay: false,

    autoPlayTimeout: 5000,

    mouseTogglesNavigation: true,

    toggleNavigationOn() {
        if (this.mouseTogglesNavigation) {
            this.showNav = true;
        }
    },

    toggleNavigationOff() {
        if (this.mouseTogglesNavigation) {
            this.showNav = false;
        }
    },

    displayNavigation() {
        return navigationButtons && this.showNav;
    },

    init() {
        this.setListingItemSize();
        this.resetObservers();
        this.autoPlay = autoPlay;
        this.loopCarousel();
        this.showNav = showNavigation;
        this.mouseTogglesNavigation = mouseTogglesNavigation;
    },

    resetObservers() {
        if (typeof this.$refs.gallery === 'undefined' || !this.$refs.gallery) return;

        let leftObserver = new IntersectionObserver(
            ([entry]) => window.requestAnimationFrame(() => this.intersectLeft(entry)),
            {
                threshold: 0.05,
            },
        );
        let rightObserver = new IntersectionObserver(
            ([entry]) => window.requestAnimationFrame(() => this.intersectRight(entry)),
            {
                threshold: 0.05,
            },
        );

        const firstChild = this.$refs.gallery.firstElementChild;
        const lastChild = this.$refs.gallery.lastElementChild;

        if (typeof firstChild !== 'undefined' && firstChild) {
            leftObserver.observe(firstChild);
        }
        if (typeof lastChild !== 'undefined' && lastChild) {
            rightObserver.observe(lastChild);
        }
    },

    setListingItemSize() {
        if (this.$refs.gallery && this.$refs.gallery.children) {
            const itemLength = this.$refs.gallery.children.length - 2;

            const resizeObserver = new ResizeObserver(entries => {
                if (this.$refs.gallery) {
                    this.listingItemSize =
                        itemLength === 0 ? this.$refs.gallery.clientWidth : this.$refs.gallery.clientWidth / itemLength;
                }
            });

            resizeObserver.observe(this.$refs.gallery);
        }
    },

    isEdgeBrowser() {
        return /Edge/.test(navigator.userAgent);
    },

    nextSlide() {
        if (this.cannotNavigateRight()) {
            return;
        }

        if (this.isEdgeBrowser()) {
            this.$refs.gallery.scrollTop = 0;
            this.$refs.gallery.scrollLeft += this.listingItemSize;
        } else {
            this.$refs.gallery.scrollBy({
                top: 0,
                left: this.listingItemSize,
                behavior: 'smooth',
            });
        }
    },

    prevSlide() {
        if (this.cannotNavigateLeft()) {
            return;
        }

        if (this.isEdgeBrowser()) {
            this.$refs.gallery.scrollTop = 0;
            this.$refs.gallery.scrollLeft -= this.listingItemSize;
        } else {
            this.$refs.gallery.scrollBy({
                top: 0,
                left: -this.listingItemSize,
                behavior: 'smooth',
            });
        }
    },

    autoPlaySlide() {
        if (this.isEdgeBrowser()) {
            this.$refs.gallery.scrollTop = 0;
            this.$refs.gallery.scrollLeft = 0;
        } else {
            this.$refs.gallery.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    },

    intersectLeft({ isIntersecting }) {
        this.listingLeftIntersecting = isIntersecting;

        if (this.$refs?.leftNavButton) {
            // Check if leftNavButton exists
            if (isIntersecting) {
                this.$refs.leftNavButton.classList.add('opacity-25');
                this.$refs.leftNavButton.classList.add('cursor-not-allowed');
            } else {
                this.$refs.leftNavButton.classList.remove('opacity-25');
                this.$refs.leftNavButton.classList.remove('cursor-not-allowed');
            }
        }
    },

    intersectRight({ isIntersecting }) {
        this.listingRightIntersecting = isIntersecting;

        if (isIntersecting) {
            if (this.autoPlay) {
                setTimeout(() => this.autoPlaySlide(), this.autoPlayTimeout);
            } else if (this.$refs?.rightNavButton) {
                // Check if rightNavButton exists
                this.$refs.rightNavButton.classList.add('opacity-25');
                this.$refs.rightNavButton.classList.add('cursor-not-allowed');
            }
        } else if (this.$refs?.rightNavButton) {
            // Check if rightNavButton exists
            this.$refs.rightNavButton.classList.remove('opacity-25');
            this.$refs.rightNavButton.classList.remove('cursor-not-allowed');
        }
    },

    cannotNavigateLeft() {
        return !this.canNavigateLeft();
    },

    cannotNavigateRight() {
        return !this.canNavigateRight();
    },

    canNavigateLeft() {
        return this.listingLeftIntersecting === false;
    },

    canNavigateRight() {
        return this.listingRightIntersecting === false;
    },

    loopCarousel() {
        if (this.autoPlay) {
            setInterval(() => {
                this.nextSlide();
            }, this.autoPlayTimeout);
        }
    },
});
