export default $wire => ({
    userMenuLoading: false,
    userMenuLoaded: false,
    isOpen: false,
    openPressed: false,
    isPrivateSellerUser: $wire.get('isPrivateSellerUser'),

    async init() {
        const self = this;

        document.addEventListener('livewire:load', () => {
            if (self.isPrivateSellerUser) {
                document.addEventListener('update-user-menu', () => {
                    self.updateUserMenu();
                });
            }
        });
    },

    toggleDropdown() {
        if (!this.isOpen) {
            this.open();
        } else {
            this.close();
        }
    },

    open() {
        if (!this.openPressed) {
            document.dispatchEvent(new CustomEvent('update-user-menu'));
        }
        this.openPressed = true;
        this.isOpen = true;
    },

    close() {
        this.isOpen = false;
    },

    async updateUserMenu() {
        if (this.userMenuLoading || this.userMenuLoaded) {
            return;
        }
        this.userMenuLoading = true;
        await $wire.updateCounters();
        this.userMenuLoading = false;
        this.userMenuLoaded = true;
    },
});
