import axios from 'axios';

export default $wire => ({
    token: document.body.querySelector('meta[name="csrf-token"]'),
    status: 0,
    timeoutLimit: 30000,
    tries: 0,
    loading: true,
    paymentProcessing: 'Vă rugăm să așteptați.',
    errors: {},

    init() {
        try {
            this.checkOrderStatus();
        } catch (error) {}
    },

    checkOrderStatus() {
        try {
            if (this.orderId) {
                let token = this.token.content;
                let orderId = this.orderId;
                let timeoutLimit = 30000;
                let tries = this.tries;
                let _ = this;

                const intervalId = window.setInterval(async function () {
                    if (tries * 5000 > timeoutLimit) {
                        clearInterval(intervalId);
                        _.loading = false;
                    }
                    try {
                        let response = await axios.get(
                            window.route(
                                'hub-product.payments.check_status',
                                { orderId: orderId },
                                {
                                    headers: {
                                        Accept: 'application/json',
                                        'X-CSRF-TOKEN': token,
                                    },
                                },
                            ),
                        );

                        if (typeof response !== 'object') {
                            response = JSON.parse(response);
                        }
                        tries++;
                        if (response.data) {
                            if (response.data.error === true) {
                                this.error = response.data.message;
                            } else {
                                let status = response.data.status;
                                if (status !== 1) {
                                    clearInterval(intervalId);
                                    _.checkIfSuccess(status);
                                }
                            }
                        }
                    } catch (error) {
                        _.loading = false;
                    }
                }, 5000);
            } else {
                this.loading = false;
            }
        } catch (e) {
            this.loading = false;
        }
    },

    checkIfSuccess(status) {
        if (status != 3) {
            window.location.href = window.route('hub-products');
        } else {
            this.loading = false;
        }
    },
});
