export default ({ eventName, errorSelector }) => ({
    init() {
        window.addEventListener(eventName, event => {
            const errorInput = this.$el.querySelector(errorSelector);
            if (errorInput) {
                errorInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
                errorInput.focus();
            }
        });
    },
});
