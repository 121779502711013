export default () => ({
    showSearch: false,

    open() {
        this.showSearch = true;
        document.querySelector('[ref=menuHamburger]').classList.add('hidden');
        document.querySelector('[ref=closeSearch]').classList.remove('hidden');

        let advanceSearchSelector = document.querySelector('#advanceSearch');
        advanceSearchSelector.classList.add('overflow-hidden');
        advanceSearchSelector.classList.remove('hidden');
        advanceSearchSelector.scrollTo(0, 0);
        document.querySelector('body').classList.add('overflow-y-hidden');
    },

    close() {
        this.showSearch = false;
        document.querySelector('[ref=menuHamburger]').classList.remove('hidden');
        document.querySelector('[ref=closeSearch]').classList.add('hidden');
        document.querySelector('#advanceSearch').classList.add('hidden');
        document.querySelector('body').classList.remove('overflow-y-hidden');
    },

    isOpen() {
        return this.showSearch === true;
    },

    isHidden() {
        return this.showSearch !== true;
    },
});
