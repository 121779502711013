import Swiper, { Keyboard, Navigation, Scrollbar, Pagination, Thumbs } from 'swiper';
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default ({
    id,
    loop,
    scrollable,
    thumbnailsId,
    navigationType,
    showNavigation,
    showPagination,
    showThumbnails,
    thumbnailsPerView,
    enableKeyboardNavigation,
}) => ({
    swiper: null,
    init() {
        const swiperElement = document.getElementById(id);

        const options = {
            loop,
        };

        const modules = [];

        if (enableKeyboardNavigation) {
            options.keyboard = {
                enabled: true,
                onlyInViewport: true,
            };

            modules.push(Keyboard);
        }

        if (showNavigation) {
            options.navigation = {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            };

            modules.push(Navigation);
        }

        if (showPagination) {
            options.pagination = {
                el: '.swiper-pagination',
                type: navigationType,
            };

            modules.push(Pagination);
        }

        if (scrollable) {
            options.scrollbar = {
                el: '.swiper-scrollbar',
            };

            modules.push(Scrollbar);
        }

        if (showThumbnails) {
            const thumbnailsElement = document.getElementById(thumbnailsId);

            options.thumbs = {
                swiper: {
                    el: thumbnailsElement,
                    freeMode: true,
                    spaceBetween: 15,
                    watchSlidesProgress: true,
                    watchSlidesVisibility: true,
                    breakpoints: {
                        320: {
                            slidesPerView: 2.5,
                        },
                        1200: {
                            slidesPerView: thumbnailsPerView,
                        },
                    },
                },
            };

            modules.push(Thumbs);
        }

        if (modules.length) {
            options.modules = modules;
        }

        this.swiper = new Swiper(swiperElement, options);
    },
});
