export default ($element, { shortVersionMaxLines }) => ({
    showClamped: false,
    isToggleable: false,

    init() {
        if (shortVersionMaxLines) {
            this.checkRenderedLineCount();
            window.addEventListener('resize', () => this.checkRenderedLineCount());
        }
    },

    checkRenderedLineCount() {
        this.$nextTick(() => {
            const numberOfLines = this.getNumberOfRenderedLines();

            this.showClamped = numberOfLines > shortVersionMaxLines;
            this.isToggleable = numberOfLines > shortVersionMaxLines;
        });
    },

    getNumberOfRenderedLines() {
        const style = window.getComputedStyle($element);
        const lineHeight = parseInt(style.lineHeight);
        const height = $element.offsetHeight;
        return Math.floor(height / lineHeight);
    },

    toggleClamping() {
        this.showClamped = !this.showClamped;
    },
});
