export default carouselId => ({
    get parentState() {
        return Alpine.store(carouselId);
    },
    get currentPage() {
        return this.parentState['currentPage'];
    },
    get lastPage() {
        return this.parentState['lastPage'];
    },
});
