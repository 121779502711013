export default ($wire, { modalEventName }) => ({
    isOpen: false,
    valid: $wire.entangle('valid').live,
    init() {
        window.addEventListener(modalEventName, event => {
            event.detail[0] === 'open' ? this.open() : this.close();
        });
    },
    open() {
        this.isOpen = true;
        document.body.style.overflow = 'hidden';
        $wire.fillIndividual();
        $wire.hideCompanyForm();
    },
    close() {
        this.isOpen = false;
        document.body.style.overflow = 'auto';
    },
    isValidField(fieldName) {
        return this.valid[fieldName];
    },
});
