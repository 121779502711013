export default ({ messages, subscribeRoute, isValidEmail }) => ({
    email: '',
    errorMessage: '',
    successMessage: '',
    messages: messages ?? {},
    subscribeRoute: subscribeRoute ?? '',
    isValidEmail: isValidEmail ?? false,
    loading: false,

    init() {
        this.validateEmail(this.email);
        console.log(this.subscribeRoute);
    },
    validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.isValidEmail = re.test(this.email.toLowerCase());
    },
    async submitForm() {
        this.loading = true;
        this.validateEmail(this.email);

        if (!this.isValidEmail) {
            this.errorMessage = this.messages.errors.invalid_email ?? '';
            this.loading = false;
            this.dispatchSuccessMessage();

            return;
        }

        this.errorMessage = '';
        this.successMessage = '';

        if (this.subscribeRoute) {
            try {
                let response = await fetch(this.subscribeRoute, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                    body: JSON.stringify({ email: this.email }),
                });

                let data = await response.json();

                if (data.status === 'success') {
                    this.successMessage = data.message;
                    this.email = '';
                    this.isValidEmail = false;
                } else {
                    this.errorMessage = data.message;
                }
            } catch (error) {
                this.errorMessage = this.messages.errors.generic_error ?? '';
            }
        }
        this.loading = false;
        this.dispatchSuccessMessage();
    },
    dispatchSuccessMessage() {
        window.dispatchEvent(new CustomEvent('submission-complete'));
    },
});
