import Swiper, { Pagination } from 'swiper';
import 'swiper/css/pagination';

export default $wire => ({
    init() {
        let slider = this.$el.getElementsByClassName('app-photo-gallery-swiper')[0];
        if (slider != null) {
            this.mySwiper = new Swiper(slider, {
                slidesPerView: 1,
                centeredSlides: true,
                loop: true,
                slideToClickedSlide: true,
                spaceBetween: 30,
                modules: [Pagination],
                breakpoints: {
                    767: {
                        slidesPerView: 3,
                    },
                },
                pagination: {
                    el: '.mobile-swiper-pagination',
                    clickable: true,
                },
            });
        }
    },
});
