import Swiper, { Keyboard, Navigation, Scrollbar, Pagination } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default $wire => ({
    mySwiper: null,
    myThumbsSwiper: null,
    anchor: null,
    mobile: null,
    screenSize: null,

    init() {
        this.mobile = $wire.get('mobile') ? $wire.entangle('mobile').live : false;
        this.anchor = $wire.get('anchor') ? $wire.entangle('anchor').live : null;

        const sliderId = $wire.get('swiperComponentId') ?? 'swiper-slider';
        const thumbsSliderId = $wire.get('swiperThumbsComponentId') ?? 'swiper-slider-thumbnails';
        const slider = document.getElementById(sliderId) ?? document.querySelector('.swiper');
        const thumbsSlider = document.getElementById(thumbsSliderId);

        if (thumbsSlider != null) {
            this.myThumbsSwiper = new Swiper(thumbsSlider, {
                modules: [Scrollbar],
                slidesPerView: 'auto',
                slideToClickedSlide: true,
                initialSlide: 0,
                scrollbar: {
                    el: thumbsSlider.querySelector('.swiper-scrollbar-gallery'),
                    draggable: true,
                },
                watchSlidesProgress: true,
                history: {
                    replaceState: true,
                },
                observer: true,
                observeParents: true,
                threshold: 10,
                shortSwipes: false,
                longSwipesRatio: 0.1,
                longSwipesMs: 100,
                ...JSON.parse($wire.get('customOptions') ?? null),
            });
        }

        if (slider != null) {
            this.mySwiper = new Swiper(slider, {
                modules: [Keyboard, Navigation, Pagination],
                slidesPerView: 1,
                initialSlide: 0,
                keyboard: {
                    enabled: true,
                },
                navigation: this.mobile
                    ? false
                    : {
                          nextEl: '.swiper-button-next',
                          prevEl: '.swiper-button-prev',
                      },
                hasNavigation: this.mobile
                    ? false
                    : {
                          replaceState: true,
                          watchState: true,
                      },
                thumbs: {
                    swiper: this.myThumbsSwiper,
                },
                direction: this.mobile ? 'vertical' : 'horizontal',
                spaceBetween: this.mobile ? 8 : 0,
                watchSlidesProgress: true,
                observer: true,
                slideToClickedSlide: true,
                history: {
                    replaceState: true,
                },
                threshold: 10,
                shortSwipes: false,
                longSwipesRatio: 0.1,
                longSwipesMs: 100,
                ...JSON.parse($wire.get('customOptions') ?? null),
            });

            if (this.myThumbsSwiper != null) {
                this.myThumbsSwiper.params.control = this.mySwiper;
                this.mySwiper.params.control = this.myThumbsSwiper;

                this.myThumbsSwiper.on('click', () => {
                    this.myThumbsSwiper.slideTo(this.myThumbsSwiper.clickedIndex);
                    this.mySwiper.slideTo(this.myThumbsSwiper.clickedIndex);
                });

                this.mySwiper.on('slideNextTransitionEnd', swiper => {
                    this.myThumbsSwiper.slideTo(swiper.activeIndex);
                });

                this.mySwiper.on('slidePrevTransitionEnd', swiper => {
                    this.myThumbsSwiper.slideTo(swiper.activeIndex);
                });
            }
        }
    },

    slideToAnchor(anchor) {
        this.myThumbsSwiper.clickedIndex = anchor;
        this.myThumbsSwiper.emit('click');
    },
});
