export default ({ show, title, type, content }) => ({
    isOpen: false,

    type: 'success',

    title: null,

    content: null,

    fill(type, title, content = null) {
        this.type = type;
        this.title = title;
        this.content = content;
    },

    close() {
        this.isOpen = false;
    },

    open(openTimeout = 400, closeTimeout = 10000) {
        setTimeout(() => {
            this.isOpen = true;
        }, openTimeout);
        setTimeout(() => {
            this.isOpen = false;
        }, closeTimeout);
    },

    init() {
        this.fill(type, title, content);

        if (show) {
            this.open();
        }

        document.addEventListener('notification-card', event => {
            let notification = event.detail;

            this.fill(notification.type, notification.title, notification.content);

            if (this.isOpen === false) {
                this.open(100);
            }
        });
    },
});
