export default $wire => ({
    images: $wire.entangle('switcherImages').live,

    currentImage: $wire.entangle('currentImage').live,

    switchFeatureImage(index) {
        let previousCurrentImage = this.currentImage;
        this.currentImage = this.images[index];
        this.images[index] = previousCurrentImage;
    },
});
